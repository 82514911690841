import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, FormControl, FormHelperText, Grid, Box, Modal, Select, MenuItem, InputLabel } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { store } from "../../../../store/store.js";

const UserProfileForm = ({ onHide }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const localRole = user.role === "IB";
	const [role, setRole] = useState(localRole);
	const [formData, setFormData] = useState({
		id: user.id,
		name: user.name,
		email: user.email,
		password: user.password,
		country: user.country,
		id: user.id,
		instagram: user.instagram,
		telegram: user.telegram,
		ib: user.ib,
		drawingsPrice: user.drawingsPrice || 0,
		strategyPrice: user.strategyPrice || 0,
	});
	const [validatePassword, setValidatePassword] = useState(user.password);
	const [errors, setErrors] = useState({});
	const [isSending, setIsSending] = useState(false);
	const history = useHistory();
	const [isModalOpen, setModalOpen] = useState(false);

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.post("https://mitserver.app:9005/getList", {
					table: "users",
					userId: user.id,
				});

				if (response.data) {
					const userData = response.data[0];
					const newData = {
						id: userData.id,
						name: userData.name,
						email: userData.email,
						password: userData.password,
						country: userData.country,
						instagram: userData.instagram,
						telegram: userData.telegram,
						ib: userData.ib,
						drawingsPrice: userData.drawingsPrice || 0,
						strategyPrice: userData.strategyPrice || 0,
					};

					const existingData = JSON.parse(localStorage.getItem("user")) || {};
					const updatedData = { ...existingData, ...newData };
					setFormData(updatedData);
					localStorage.setItem("user", JSON.stringify(updatedData));
				}
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};

		fetchUserData();
	}, []);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		if (name === "validatePassword") {
			setValidatePassword(value);
			return;
		}
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const validateForm = () => {
		console.log("VALIDATE");
		let tempErrors = {};
		if (formData.name.trim() === "") tempErrors.name = "Nombre requerido";
		if (formData.email.trim() === "") tempErrors.email = "Email inválido";
		if (formData.password.length < 4) tempErrors.password = "Contraseña muy corta";
		if (formData.password !== validatePassword) tempErrors.validatePassword = "Las contraseñas no coinciden";
		if (formData.country.trim() === "") tempErrors.country = "País requerido";
		if (formData.ib === "active") {
			if (!formData.drawingsPrice || isNaN(formData.drawingsPrice)) {
				tempErrors.drawingsPrice = "Precio de Análisis Técnico inválido";
			}
			if (!formData.strategyPrice || isNaN(formData.strategyPrice)) {
				tempErrors.strategyPrice = "Precio de Estrategias inválido";
			}
		}
		console.log("ERRORS", errors);
		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) return;

		setIsSending(true);
		console.log("FORM", formData);
		axios
			.post(`https://mitserver.app:9005/updateUser`, formData)
			.then((response) => {
				const user = JSON.parse(localStorage.getItem("user"));
				user.name = formData.name;
				user.email = formData.email;
				user.password = formData.password;
				user.country = formData.country;
				user.instagram = formData.instagram;
				user.telegram = formData.telegram;
				user.drawingsPrice = formData.drawingsPrice;
				user.strategyPrice = formData.strategyPrice;

				localStorage.setItem("user", JSON.stringify(user));
				toast.success("Perfil actualizado con éxito", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				onHide(false);
			})
			.catch((error) => {
				console.error("Error updating user data:", error);
				toast.error("Error al actualizar el perfil");
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	const handleLogout = () => {
		store.getState().reset();
		localStorage.clear();
		history.push("/login");
		window.location.reload();
	};

	const handleBeTicksyRequest = async () => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const response = await axios.post("https://mitserver.app:9005/beTicksy", {
				userId: user.id,
			});
			console.log("RESPONSE", response.data);
			if (response.data.ok) {
				toast.success(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			} else {
				console.log("ERROR");
				toast.error(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			}
		} catch (error) {
			toast.error("Intenta denuevo", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
		}
	};

	const TicksyInfoModal = ({ open, onClose }) => (
		<Modal
			open={open}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<Box
				sx={{
					backgroundColor: "#131722",
					padding: "20px",
					borderRadius: "10px",
					boxShadow: "0 0 10px #4599d9",
					maxWidth: "400px",
					textAlign: "center",
				}}>
				<Typography
					variant="h6"
					sx={{
						color: "#4599d9",
						fontFamily: "'Orbitron', sans-serif",
						marginBottom: "10px",
					}}>
					¿Qué es ser un Ticksy?
				</Typography>
				<Typography
					variant="body1"
					sx={{
						color: "white",
						fontFamily: "'Orbitron', sans-serif",
					}}>
					Ser un Ticksy te permite compartir análisis y estrategias ademas de poder postear en la red Ticksy. Puedes ganar por tus
					contribuciones el monto que gustes y el reconocimiento en la comunidad. Estamos creando nuevas funcionalidades que te permitirán
					expandir tu alcance y conseguir una mayor cantidad de seguidores.
					<br />
					<br />
					<span style={{ fontWeight: "bold", color: "#f74712" }}>¡Únete y empieza a compartir!</span>
				</Typography>
			</Box>
		</Modal>
	);

	return (
		<div style={{ backgroundColor: "#131722" }}>
			<Typography
				sx={{
					fontFamily: "'Orbitron', sans-serif",
					fontSize: 18,
					p: 1,
					textAlign: "center",
					color: "#fff",
					textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
				}}
				gutterBottom>
				Perfil
			</Typography>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="name"
							label="Nombre"
							value={formData.name}
							onChange={handleInputChange}
							error={!!errors.name}
							helperText={errors.name}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="email"
							label="Email"
							value={formData.email}
							onChange={handleInputChange}
							error={!!errors.email}
							helperText={errors.email}
							disabled
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="password"
							label="Contraseña"
							value={formData.password}
							onChange={handleInputChange}
							error={!!errors.password}
							helperText={errors.password}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							fullWidth
							name="validatePassword"
							label="Validar contraseña"
							value={validatePassword}
							onChange={handleInputChange}
							error={!!errors.validatePassword}
							helperText={errors.validatePassword}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth error={!!errors.country}>
							<InputLabel id="country-label" sx={{ color: "#fff" }}>
								País
							</InputLabel>
							<Select
								labelId="country-label"
								name="country"
								value={formData.country}
								onChange={handleInputChange}
								label="País"
								sx={{
									color: "#fff",
									"& .MuiOutlinedInput-notchedOutline": { borderColor: "#4599d9" },
									"&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#f74712" },
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#4599d9" },
								}}>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								<MenuItem value="argentina">Argentina</MenuItem>
								<MenuItem value="bolivia">Bolivia</MenuItem>
								<MenuItem value="brasil">Brasil</MenuItem>
								<MenuItem value="chile">Chile</MenuItem>
								<MenuItem value="colombia">Colombia</MenuItem>
								<MenuItem value="costaRica">Costa Rica</MenuItem>
								<MenuItem value="cuba">Cuba</MenuItem>
								<MenuItem value="ecuador">Ecuador</MenuItem>
								<MenuItem value="salvador">El Salvador</MenuItem>
								<MenuItem value="guatemala">Guatemala</MenuItem>
								<MenuItem value="haiti">Haití</MenuItem>
								<MenuItem value="honduras">Honduras</MenuItem>
								<MenuItem value="mexico">México</MenuItem>
								<MenuItem value="nicaragua">Nicaragua</MenuItem>
								<MenuItem value="panama">Panamá</MenuItem>
								<MenuItem value="paraguay">Paraguay</MenuItem>
								<MenuItem value="peru">Perú</MenuItem>
								<MenuItem value="republicaDominicana">República Dominicana</MenuItem>
								<MenuItem value="uruguay">Uruguay</MenuItem>
								<MenuItem value="venezuela">Venezuela</MenuItem>
							</Select>
							<FormHelperText>{errors.country}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="instagram"
							label="Instagram"
							value={formData.instagram}
							onChange={handleInputChange}
							error={!!errors.instagram}
							helperText={errors.instagram}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							name="telegram"
							label="Telegram"
							value={formData.telegram}
							onChange={handleInputChange}
							error={!!errors.telegram}
							helperText={errors.telegram}
							sx={{
								"& .MuiOutlinedInput-root": {
									"& fieldset": { borderColor: "#4599d9" },
									"&:hover fieldset": { borderColor: "#f74712" },
									"&.Mui-focused fieldset": { borderColor: "#4599d9" },
								},
								"& .MuiInputLabel-root": { color: "#fff" },
								"& .MuiInputBase-input": { color: "#fff" },
							}}
						/>
					</Grid>

					{formData.ib === "active" && (
						<Grid container spacing={5}>
							<Grid item xs={5.95}>
								<TextField
									label="Precio de Análisis"
									variant="outlined"
									fullWidth
									margin="dense"
									name="drawingsPrice"
									value={formData.drawingsPrice}
									onChange={handleInputChange}
									error={!!errors.drawingsPrice}
									helperText={errors.drawingsPrice}
									sx={{
										ml: 2,
										"& .MuiOutlinedInput-root": {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#4599d9" },
										},
										"& .MuiInputLabel-root": { color: "#fff" },
										"& .MuiInputBase-input": { color: "#fff" },
									}}
								/>
							</Grid>
							<Grid item xs={5.95}>
								<TextField
									label="Precio de Estrategias"
									variant="outlined"
									fullWidth
									margin="dense"
									name="strategyPrice"
									value={formData.strategyPrice}
									onChange={handleInputChange}
									error={!!errors.strategyPrice}
									helperText={errors.strategyPrice}
									sx={{
										ml: 0,
										"& .MuiOutlinedInput-root": {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#4599d9" },
										},
										"& .MuiInputLabel-root": { color: "#fff" },
										"& .MuiInputBase-input": { color: "#fff" },
									}}
								/>
							</Grid>
						</Grid>
					)}

					<Grid item xs={12} lg={12}>
						<Box display="flex" justifyContent="center" gap={2}>
							<Button
								variant="contained"
								type="submit"
								sx={{
									backgroundColor: "#4599d9",
									color: "#fff",
									border: "2px solid #4599d9",
									borderRadius: "15px",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									padding: "10px 20px",
									cursor: "pointer",
									transition: "all 0.3s ease",
									textShadow: "0 0 10px #fff",
									boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
									"&:hover": {
										backgroundColor: "#3d87c3",
										boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
									},
								}}>
								{isSending ? "Enviando..." : "Actualizar"}
							</Button>
							<Button
								variant="contained"
								onClick={handleLogout}
								sx={{
									backgroundColor: "#f74712",
									color: "#fff",
									border: "2px solid #f74712",
									borderRadius: "15px",
									fontWeight: "bold",
									fontFamily: "'Orbitron', sans-serif",
									padding: "10px 20px",
									cursor: "pointer",
									transition: "all 0.3s ease",
									textShadow: "0 0 10px #fff",
									boxShadow: "0 0 5px #f74712, 0 0 10px #f74712",
									"&:hover": {
										backgroundColor: "#d93d0d",
										boxShadow: "0 0 10px #f74712, 0 0 20px #f74712",
									},
								}}>
								Cerrar Sesión
							</Button>
						</Box>
					</Grid>
				</Grid>
			</form>

			<Box sx={{ marginTop: "20px", textAlign: "center" }}>
				{formData.ib === "active" ? (
					<Box
						sx={{
							marginTop: "20px",
							textAlign: "center",
							boxShadow: "0 0 10px #4599d9",
							padding: "10px",
							borderRadius: "10px",
						}}>
						<Typography
							variant="body1"
							sx={{
								fontFamily: "'Orbitron', sans-serif",
								color: "green",
								// textShadow: "0 0 5px #fff",
							}}>
							Ya eres un Ticksy
						</Typography>

						<Typography
							variant="body1"
							sx={{
								fontFamily: "'Orbitron', sans-serif",
								color: "white",
								textAlign: "center",
								textShadow: "0 0 5px #4599d9",
							}}>
							Código Ticksy <span style={{ color: "#4599d9" }}>GRX{user.id}</span>
						</Typography>
					</Box>
				) : (
					<Box>
						<Typography
							variant="body2"
							sx={{
								fontFamily: "'Orbitron', sans-serif",
								color: "white",
								cursor: "pointer",
								mb: 1,
								"&:hover": { textDecoration: "underline" },
							}}
							onClick={handleOpenModal}>
							¿Quieres ser un <span style={{ color: "#4599d9" }}>Ticksy</span>?
						</Typography>

						<TicksyInfoModal open={isModalOpen} onClose={handleCloseModal} />

						<Button
							variant="contained"
							onClick={handleBeTicksyRequest}
							sx={{
								backgroundColor: "#4599d9",
								fontFamily: "'Orbitron', sans-serif",
								fontWeight: 700,
								textTransform: "uppercase",
								letterSpacing: "1px",
								color: "#fff",
								boxShadow: "0 0 10px #4599d9",
								borderRadius: "10px",
								textShadow: "0 0 10px #fff",
								"&:hover": {
									backgroundColor: "#357ab7",
									boxShadow: "0 0 20px #4599d9",
								},
								marginBottom: "10px",
							}}>
							Solicitar
						</Button>
					</Box>
				)}
			</Box>

			<Typography
				variant="body1"
				sx={{
					fontFamily: "'Orbitron', sans-serif",
					color: role ? "#3B3B3B" : "white",
					marginTop: "40px",
					textAlign: "center",
					textShadow: "0 0 5px #4599d9",
				}}>
				TradeAI Copyright @2024 - Version 1.0.2
			</Typography>
		</div>
	);
};

export default UserProfileForm;
