// src/components/TVChartContainer/pages/components/HorizontalTabs/HorizontalTabs.component.jsx

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export function HorizontalTabs({ items, className, activeTab, onTabChange }) {
	return (
		<Box sx={{ width: "100%", backgroundColor: "#131722", borderRadius: "20px", padding: "20px" }} className={className}>
			<Box sx={{ borderBottom: 1, borderColor: "rgba(69, 153, 217, 0.5)" }}>
				<Tabs
					value={activeTab}
					onChange={(event, newValue) => onTabChange(newValue)}
					aria-label="basic tabs example"
					sx={{
						"& .MuiTabs-indicator": {
							backgroundColor: "#4599d9",
							height: 3,
							boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
						},
					}}>
					{items.map((item) => (
						<Tab
							key={item.id}
							label={item.title}
							{...a11yProps(item.id)}
							sx={{
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
								"&.Mui-selected": {
									color: "#4599d9",
									textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
								},
								"&:hover": {
									color: "#f74712",
									textShadow: "0 0 10px #f74712, 0 0 20px #f74712",
								},
							}}
						/>
					))}
				</Tabs>
			</Box>
			{items.map((item) => (
				<CustomTabPanel key={item.id} value={activeTab} index={item.id}>
					{item.component}
				</CustomTabPanel>
			))}
		</Box>
	);
}

HorizontalTabs.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			component: PropTypes.node.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	activeTab: PropTypes.number.isRequired,
	onTabChange: PropTypes.func.isRequired,
};
