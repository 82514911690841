import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import { store } from "../../../store/store";
import { toast } from "react-toastify";
import axios from "axios";

export function AddToTicksy({ open, onClose }) {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const user = JSON.parse(localStorage.getItem("user"));
	const [image, setImage] = useState(null);
	const [symbol, setSymbol] = useState(null);

	useEffect(() => {
		async function callFirst() {
			if (open) {
				const currentImage = store.getState().image;
				addWatermark(currentImage).then((watermarkedImage) => {
					setImage(watermarkedImage);
				});

				const symbolInfo = await localStorage.getItem("symbolInfo");
				if (symbolInfo) {
					const parsedSymbolInfo = JSON.parse(symbolInfo);
					setSymbol(parsedSymbolInfo.displayName);
				}
			}
		}
		callFirst();
	}, [open]);

	const addWatermark = (base64Image) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = base64Image;

			img.onload = () => {
				const canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;

				const ctx = canvas.getContext("2d");

				// Draw original image
				ctx.drawImage(img, 0, 0);

				// Add watermark
				const logo = new Image();
				logo.src = "/assets/logoMV.png "; // Your logo path

				logo.onload = () => {
					// Position watermark in bottom right corner
					const watermarkWidth = img.width * 0.2; // 20% of image width
					const ratio = logo.height / logo.width;
					const watermarkHeight = watermarkWidth * ratio;

					ctx.globalAlpha = 0.5; // Transparency
					ctx.drawImage(
						logo,
						img.width - watermarkWidth - 120, // 20px padding from right
						img.height - watermarkHeight - 70, // 20px padding from bottom
						watermarkWidth,
						watermarkHeight
					);

					resolve(canvas.toDataURL());
				};
			};
		});
	};

	const handleSubmit = async () => {
		try {
			const post = {
				userId: user.id,
				symbol: symbol,
				author: user.name,
				date: new Date().toISOString(),
				image: image,
				title: title,
				description: description,
				instagram: user.instagram || "",
				telegram: user.telegram || "",
			};
			console.log("POST", post);

			const response = await axios.post("https://mitserver.app:9005/savePost", post);
			if (response.data.ok) {
				toast.success(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			} else {
				toast.error(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			}
			onClose();
		} catch (error) {
			console.error("Error creating post:", error);
			toast.error("Error creating post");
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "flex-end",
			}}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "35%",
					height: "95vh",
					backgroundColor: "#131722",
					overflowY: "auto",
					padding: "20px",
					borderRadius: "30px",
					boxShadow: "0 0 10px #fd522c, 0 0 40px #fd522c",
					"&::-webkit-scrollbar": {
						width: "8px",
					},
					"&::-webkit-scrollbar-track": {
						background: "#1a1f2d",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "#4599d9",
						borderRadius: "4px",
					},
				}}>
				<Typography
					variant="h4"
					sx={{
						position: "sticky",
						color: "transparent",
						backgroundImage: "linear-gradient(45deg, #f74712, #f74712, #62fee1, #4599d9)",
						backgroundClip: "text",
						WebkitBackgroundClip: "text",
						fontFamily: "'Orbitron', sans-serif",
						textAlign: "center",
						fontWeight: "bold",
						mb: 4,
						zIndex: 1000,
						backgroundColor: "#282828",
					}}>
					TICKSY POST
				</Typography>

				<TextField
					fullWidth
					label="Title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					sx={{
						marginBottom: "20px",
						"& .MuiOutlinedInput-root": {
							color: "#fff",
							"& fieldset": {
								borderColor: "#4599d9",
							},
							"&:hover fieldset": {
								borderColor: "#4599d9",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#fff",
						},
					}}
				/>

				<TextField
					fullWidth
					multiline
					rows={4}
					label="Description"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					sx={{
						marginBottom: "20px",
						"& .MuiOutlinedInput-root": {
							color: "#fff",
							"& fieldset": {
								borderColor: "#4599d9",
							},
							"&:hover fieldset": {
								borderColor: "#4599d9",
							},
						},
						"& .MuiInputLabel-root": {
							color: "#fff",
						},
					}}
				/>

				<Button
					fullWidth
					variant="contained"
					onClick={handleSubmit}
					sx={{
						backgroundColor: "#4599d9",
						fontFamily: "'Orbitron', sans-serif",
						fontWeight: 700,
						textTransform: "uppercase",
						letterSpacing: "1px",
						boxShadow: "0 0 10px #4599d9",
						color: "#fff",
						"&:hover": {
							backgroundColor: "#357ab7",
							boxShadow: "0 0 20px #4599d9",
						},
						marginBottom: "60px",
					}}>
					Post Analisis
				</Button>

				<Box
					sx={{
						mb: 4,
						pb: 4,
						borderTop: "1px solid rgba(69, 153, 217, 0.2)",
						pt: 2,
					}}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 2,
						}}>
						<Box sx={{ alignItems: "center" }}>
							<Typography
								variant="h6"
								sx={{
									color: "#fff",
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{user.name}
							</Typography>
							<Typography
								sx={{
									color: "#fff",
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{symbol}
							</Typography>
						</Box>
						<Typography
							sx={{
								color: "#4599d9",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							{new Date().toISOString().split("T")[0]}
						</Typography>
					</Box>
					<Box sx={{ mb: 2 }}>
						<img
							src={image} // Your base64 image string
							alt="Trading Analysis Preview"
							style={{
								width: "100%",
								borderRadius: "10px",
								boxShadow: "0 0 10px rgba(69, 153, 217, 0.3)",
							}}
						/>
					</Box>
					<Typography
						variant="h5"
						sx={{
							color: "#fff",
							fontFamily: "'Orbitron', sans-serif",
							marginBottom: "20px",
						}}>
						{title}
					</Typography>
					<Typography
						sx={{
							color: "#fff",
							mb: 2,
							fontFamily: "'Orbitron', sans-serif",
						}}>
						{description}
					</Typography>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							color: "#4599d9",
						}}>
						<Box
							component="a"
							href={`https://instagram.com/${user.instagram}`}
							target="_blank"
							rel="noopener noreferrer"
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
								cursor: "pointer",
								"&:hover": { opacity: 0.8 },
								color: "#fd522c",
								textDecoration: "none",
							}}>
							<InstagramIcon />
							<Typography sx={{ color: "#4599d9" }}>{user.instagram || ""}</Typography>
						</Box>
						<Box
							component="a"
							href={`https://t.me/${user.telegram}`}
							target="_blank"
							rel="noopener noreferrer"
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 1,
								cursor: "pointer",
								"&:hover": { opacity: 0.8 },
								color: "#fd522c",
								textDecoration: "none",
							}}>
							<TelegramIcon />
							<Typography sx={{ color: "#4599d9" }}>{user.telegram || ""}</Typography>
						</Box>
					</Box>{" "}
				</Box>
			</Box>
		</Modal>
	);
}
