import React, { useState, useEffect } from "react";
import { Modal, Box, List, ListItem, ListItemText, Typography, Grid, ListItemButton, Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { store } from "../../../../store/store.js";
import axios from "axios";
import { toast } from "react-toastify";
import Payments from "./Payments.js";
import "react-credit-cards/es/styles-compiled.css";

const DrawingsPage = ({ open, onClose }) => {
	const tvWidget = store((state) => state.chart);
	const user = JSON.parse(localStorage.getItem("user"));
	const [traderToCopy, setTraderToCopy] = useState("");
	const [selectedBot, setSelectedBot] = useState(null);
	const [traderName, setTraderName] = useState("");
	const [drawings, setDrawings] = useState([]);
	const [matchTrader, setMatchTrader] = useState(false);
	const [showPayments, setShowPayments] = useState(false);
	const [service, setService] = useState("drawings");
	const [price, setPrice] = useState(0);
	const [isSearching, setIsSearching] = useState(false);
	const botDetails = {
		Guardar: {
			images: "/assets/save.webp",
			details:
				"Guarda tus gráficos y análisis técnicos personalizados para compartirlos con otros traders. Monetiza tu experiencia ofreciendo tus análisis a cambio de una tarifa. Crea una comunidad de trading y genera ingresos pasivos compartiendo tus conocimientos del mercado.",
		},
		Cargar: {
			images: "/assets/load.webp",
			details:
				"Carga tus gráficos y análisis técnicos guardados previamente. Accede a una biblioteca de análisis de otros traders expertos. Mejora tus estrategias de trading aprendiendo de los mejores y adaptando sus ideas a tu estilo de trading.",
		},
		Eliminar: {
			images: "/assets/remove.webp",
			details:
				"Elimina gráficos y análisis técnicos guardados que ya no necesitas. Mantén tu espacio de trabajo organizado y enfocado en tus estrategias actuales. Asegúrate de conservar solo la información más relevante y actualizada para tus operaciones de trading.",
		},
	};

	useEffect(() => {
		const saveTrader = async () => {
			const trader = await localStorage.getItem("traderToCopy");
			setTraderToCopy(trader);
		};
		saveTrader();
	}, []);

	const handleSearch = async () => {
		if (isSearching) return;

		setIsSearching(true);

		try {
			const copyTo = traderToCopy.replace("GRX", "");
			const payload = { userId: user.id, toFollow: copyTo };

			if (!payload.userId || typeof payload.userId !== "number") {
				console.error("Invalid userId:", payload.userId);
				return;
			}

			if (!payload.toFollow || payload.toFollow.trim() === "") {
				toast.error("Digite el código de Trader a seguir", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				return;
			}

			axios
				.post("https://mitserver.app:9005/findIdDrawings", payload)
				.then((response) => {
					setTraderName(response.data.user);
					const validDrawings = response.data.paidDrawings.filter((drawing) => {
						if (typeof drawing === "string") {
							return drawing.trim() !== "";
						}
						return typeof drawing === "object" && drawing.id;
					});

					setDrawings(validDrawings);
					setPrice(response.data.drawingsPrice);

					if (validDrawings.length > 0) {
						const matchingDrawing = validDrawings.find((drawing) => drawing === String(copyTo));

						if (matchingDrawing) {
							setMatchTrader(true);
							const matchingId = matchingDrawing;
						} else {
							setMatchTrader(false);
						}
					}
				})
				.catch((error) => {
					console.error("Error loading drawings:", error);
				});
		} catch (error) {
			console.error("Error during search:", error);
		} finally {
			setIsSearching(false);
		}
	};

	let savedData = [];

	const saveDrawings = () => {
		// Get existing drawings from localStorage and ensure it's an array
		const existingDrawings = localStorage.getItem("chartDrawings");
		savedData = existingDrawings ? (Array.isArray(JSON.parse(existingDrawings)) ? JSON.parse(existingDrawings) : []) : [];

		const drawings = tvWidget
			.chart()
			.getAllShapes()
			.map((shape) => {
				const shapeDetails = tvWidget.chart().getShapeById(shape.id);
				const properties = shapeDetails ? shapeDetails.getProperties() : {};
				const points = shapeDetails ? shapeDetails.getPoints() : [];
				console.log("SHAPE", points);
				return {
					id: shape.id,
					name: shape.name,
					points: points.map((p) => ({ time: p.time, price: p.price })),
					properties: properties,
				};
			});

		const symbol = tvWidget.chart().symbol();

		const existingSymbolIndex = savedData.findIndex((item) => item.symbol === symbol);

		if (existingSymbolIndex !== -1) {
			savedData[existingSymbolIndex].drawings = drawings;
		} else {
			savedData.push({
				symbol: symbol,
				drawings: drawings,
			});
		}
		localStorage.setItem("chartDrawings", JSON.stringify(savedData));

		axios
			.post("https://mitserver.app:9005/saveDrawings", { userId: user.id, drawings: savedData })
			.then((response) => {
				console.log("Drawings saved successfully:", response.data);
				toast.success("Dibujos guardados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				onClose(true);
			})
			.catch((error) => {
				console.error("Error saving drawings:", error);
			});
	};

	const loadDrawings = (userDrawings) => {
		const symbol = tvWidget.chart().symbol();
		const payload = userDrawings ? { userId: userDrawings } : { userId: user.id };

		axios
			.post("https://mitserver.app:9005/loadDrawings", payload)
			.then((response) => {
				const drawingsData = response.data.drawingsData;
				const symbolData = drawingsData.find((item) => item.symbol === symbol);
				const drawings = symbolData ? symbolData.drawings : [];

				if (drawings && drawings.length > 0) {
					tvWidget.chart().removeAllShapes();

					drawings.forEach((drawing) => {
						const shapeOptions = drawing.properties;
						const shapePoints = drawing.points;

						if (shapePoints.length > 1) {
							tvWidget.chart().createMultipointShape(shapePoints, {
								shape: drawing.name,
								...shapeOptions,
							});
						} else if (shapePoints.length === 1) {
							tvWidget.chart().createShape(shapePoints[0], {
								shape: drawing.name,
								...shapeOptions,
							});
						} else {
							console.log(`Invalid points for drawing: ${drawing.name}`);
						}
					});
					toast.success("Graficos cargados exitosamente para el símbolo: " + symbol, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					onClose(true);
				} else {
					toast.success("No hay gráficos para el símbolo: " + symbol, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					onClose(true);
				}
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const deleteDrawings = async () => {
		const symbol = tvWidget.chart().symbol();
		const payload = { userId: user.id, symbol: symbol };

		axios
			.post("https://mitserver.app:9005/removeDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				onClose(true);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleBotSelection = () => {
		if (selectedBot === "Guardar") {
			saveDrawings();
		} else if (selectedBot === "Cargar") {
			const copyTo = traderToCopy.replace("GRX", "");
			loadDrawings(copyTo);
		} else if (selectedBot === "Eliminar") {
			deleteDrawings();
		}
	};

	const handleFollower = (traderId) => {
		const payload = { userId: user.id, toFollow: traderId };
		axios
			.post("https://mitserver.app:9005/followDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				onClose(true);
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleSaveIndicators = () => {
		console.log("Saving indicators...", Object.keys(tvWidget));
		tvWidget
			.saveIndicatorTemplate()
			.then((template) => {
				localStorage.setItem("indicatorTemplate", JSON.stringify(template));
				console.log("Indicators saved:", template);
			})
			.catch((error) => {
				console.error("Error saving indicators:", error);
			});
	};

	const handleLoadIndicators = () => {
		const template = JSON.parse(localStorage.getItem("indicatorTemplate"));
		if (template) {
			tvWidget
				.loadIndicatorTemplate(template)
				.then(() => {
					console.log("Indicators loaded successfully");
				})
				.catch((error) => {
					console.error("Error loading indicators:", error);
				});
		} else {
			console.warn("No saved indicators found");
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "60%",
					height: "75%",
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					display: "flex",
					borderRadius: "30px",
					boxShadow: "0 0 10px #4599d9, 0 0 40px #4599d9",
				}}>
				<Typography
					variant="h4"
					sx={{
						color: "#fff",
						fontFamily: "'Orbitron', sans-serif",
						fontSize: "2rem",
						fontWeight: 700,
						textTransform: "uppercase",
						letterSpacing: "2px",
						position: "absolute",
						right: "35%",
					}}>
					Analisis Técnicos
				</Typography>

				<Box
					sx={{
						width: "30%",
						mr: 2,
						backgroundColor: "transparent",
						overflow: "auto",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<List sx={{ padding: 0 }}>
						{Object.keys(botDetails).map((bot) => (
							<ListItem
								key={bot}
								sx={{
									mb: 4,
									borderRadius: 2,
									backgroundColor: selectedBot === bot ? "#f74712" : "#2a2e39",
									boxShadow: selectedBot === bot ? "0 0 10px #f74712, 0 0 30px #f74712" : "0 0 5px #f74712, 0 0 15px #f74712",
									transition: "all 0.3s ease",
									"&:hover": {
										transform: "scale(1.05)",
										boxShadow: "0 0 10px #f74712, 0 0 30px #f74712",
									},
								}}>
								<ListItemButton onClick={() => setSelectedBot(bot)}>
									<ListItemText
										primary={bot}
										sx={{
											color: "#fff",
											textAlign: "center",
											"& .MuiListItemText-primary": {
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.5rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "2px",
											},
										}}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>

				<Box
					sx={{
						width: "70%",
						backgroundColor: "transparent",
						padding: 3,
						overflow: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
					}}>
					{selectedBot && (
						<>
							<Grid container>
								<Grid item xs={12}>
									<Box display="flex" justifyContent="center" width="100%">
										<img
											src={botDetails[selectedBot].images}
											alt={`${selectedBot} image`}
											style={{
												width: "95%",
												borderRadius: 8,
												boxShadow: "0 0 5px #4599d9, 0 0 20px #4599d9",
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="body1"
										sx={{
											color: "#fff",
											textAlign: "center",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "1rem",
											fontWeight: 500,
											letterSpacing: "1px",
											mt: 2,
										}}>
										{botDetails[selectedBot].details}
									</Typography>
								</Grid>
								{selectedBot === "Cargar" && (
									<Box sx={{ width: "100%" }}>
										<Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
											<TextField
												label="Trader a seguir"
												variant="outlined"
												value={traderToCopy}
												onChange={async (e) => {
													setTraderToCopy(e.target.value);
													await localStorage.setItem("traderToCopy", e.target.value);
												}}
												sx={{ flexGrow: 1 }}
											/>
											<IconButton
												onClick={handleSearch}
												disabled={isSearching}
												sx={{
													ml: 1,
													backgroundColor: isSearching ? "#ccc" : "#f74712",
													"&:hover": {
														backgroundColor: isSearching ? "#ccc" : "#d93d0d",
													},
												}}>
												{isSearching ? <CircularProgress size={24} color="inherit" /> : <SearchIcon sx={{ color: "#fff" }} />}
											</IconButton>
										</Box>
										<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
											<Typography
												variant="body1"
												sx={{
													color: "#fff",
													textAlign: "center",
													fontFamily: "'Orbitron', sans-serif",
													fontSize: "1rem",
													fontWeight: 500,
													letterSpacing: "1px",
													mt: 1,
													mb: 3,
												}}>
												{traderName}
											</Typography>
										</Box>
									</Box>
								)}

								<Box display="flex" justifyContent="center" width="100%">
									{selectedBot === "Cargar" && (
										<Button
											variant="contained"
											onClick={() => loadDrawings()}
											sx={{
												mt: 2,
												mr: 6,
												color: "#fff",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.2rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "1px",
												backgroundColor: "#4599d9",
												boxShadow: "0 0 5px #4599d9, 0 0 15px #4599d9",
												padding: "12px 24px",
												"&:hover": {
													backgroundColor: "#2a5d8a",
												},
											}}>
											Cargar
										</Button>
									)}

									{matchTrader ? (
										<Button
											variant="contained"
											onClick={() => handleBotSelection(selectedBot)}
											sx={{
												mt: 2,
												color: "#fff",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.2rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "1px",
												backgroundColor: "#f74712",
												boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
												padding: "12px 24px",
												"&:hover": {
													backgroundColor: "#d93d0d",
												},
											}}>
											{selectedBot === "Cargar" ? "Cargar" : selectedBot === "Eliminar" ? "Eliminar" : "Guardar"}
										</Button>
									) : (
										<Button
											variant="contained"
											onClick={() => {
												const resp =
													selectedBot === "Cargar" ? "Comprar" : selectedBot === "Eliminar" ? "Eliminar" : "Guardar";
												if (resp === "Comprar") {
													if (traderName) {
														setShowPayments(true);
													} else {
														toast.error("Debes buscar un trader a seguir", {
															position: "top-right",
															autoClose: 3000,
															hideProgressBar: false,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															theme: "dark",
														});
													}
												} else {
													handleBotSelection(selectedBot);
												}
											}}
											sx={{
												mt: 2,
												color: "#fff",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1.2rem",
												fontWeight: 700,
												textTransform: "uppercase",
												letterSpacing: "1px",
												backgroundColor: "#f74712",
												boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
												padding: "12px 24px",
												"&:hover": {
													backgroundColor: "#d93d0d",
												},
											}}>
											{selectedBot === "Cargar" ? "Comprar" : selectedBot === "Eliminar" ? "Eliminar" : "Guardar"}
										</Button>
									)}

									<Payments
										openPayments={showPayments}
										onClosePayments={() => setShowPayments(false)}
										service={service}
										price={price}
									/>
								</Box>
							</Grid>
						</>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default DrawingsPage;
