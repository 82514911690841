// src/components/TVChartContainer/pages/UserProfile.page.jsx

import { useState, useEffect } from "react";
import { HorizontalTabs, Offcanvas, AccountsPage } from "./components";
import UserProfileForm from "./components/UserForm.page";
import ChartTypesPage from "./components/ChartTypes.page";

export const UserProfilePage = ({ showReportPage, onHide, defaultTab = 0 }) => {
	const [activeTab, setActiveTab] = useState(defaultTab);
	useEffect(() => {
		setActiveTab(defaultTab); // Update activeTab when defaultTab changes
	}, [defaultTab]);
	const items = [
		{
			id: 0,
			title: "Usuario",
			component: <UserProfileForm onHide={onHide} />,
		},
		{
			id: 1,
			title: "Deriv",
			component: <AccountsPage onHide={onHide} />,
		},
		{
			id: 2,
			title: "Gráfico",
			component: <ChartTypesPage onHide={onHide} />,
		},
	];

	return (
		<>
			<Offcanvas onHide={onHide} title="Configuración" showState={showReportPage} variant={"md"}>
				<HorizontalTabs items={items} activeTab={activeTab} onTabChange={setActiveTab} />
			</Offcanvas>
		</>
	);
};
