import { getLastCloses } from "../../streaming.js";
import { store } from "../../../../store/store";
import socket from "../../../../socket/Socket.js";

export const runSpikeShield = (tvWidget, candlesQty, retCandles, amount) => {
	amount = amount / retCandles;
	let cross1M = false;
	let cross5M = false;
	let smaData1M = [];
	let rsiData = [];
	let sma5_1M = null;
	let sma9_1M = null;
	let sma200_1M = null;
	let maxDrawdown = 0;
	let runningBalance = 0;
	let lowestBalance = 0;
	let totalCrosses = 0;
	let winningCrosses = 0;
	let totalProfit = 0;
	let totalLoss = 0;
	let timeFrame = 60;

	const currentPrice = Number(store.getState().lastBar.close);
	const time = Number(store.getState().lastBar.epoch);
	let trade = false;

	const init = async () => {
		const ohlcData1M = await getLastCloses(timeFrame, candlesQty);
		console.log("RUN SPIKE SHIELD", ohlcData1M);

		if (ohlcData1M.length > 0) {
			await processData1M(ohlcData1M);
			// await drawEMA(tvWidget, smaData1M, "orange", "green");
		}
	};
	init();

	// setInterval(() => {
	// 	const newTime = Number(store.getState().lastBar.epoch);
	// 	const newPrice = Number(store.getState().lastBar.close);

	// 	smaData1M.push({
	// 		time: newTime,
	// 		price: newPrice,
	// 		sma5: calculateSMA(5, [...smaData1M, { time: newTime, close: newPrice }]).pop(),
	// 		sma9: calculateSMA(9, [...smaData1M, { time: newTime, close: newPrice }]).pop(),
	// 	});

	// 	const current = smaData1M[smaData1M.length - 1];
	// 	const previous = smaData1M[smaData1M.length - 2];
	// 	const symbol = tvWidget.activeChart().symbol();
	// 	const rsi = calculateRSI(smaData1M.slice(1), 9);

	// 	if (rsi < 60) {
	// 		if (previous && current && previous.sma5 > previous.sma9 && current.sma5 < current.sma9 && symbol.includes("BOOM")) {
	// 			const body = {
	// 				account: "VRTC10125069",
	// 				contractType: "MULTDOWN",
	// 				derivMultiplier: "400",
	// 				multiplier: "4",
	// 				orderPosition: false,
	// 				orderPrice: "0",
	// 				orderType: "Market",
	// 				pip: 0.001,
	// 				pipValue: 0.03116,
	// 				pipsSL: "3.34",
	// 				pipsTP: "3.34",
	// 				preStopLoss: null,
	// 				riskAmount: "1.02",
	// 				slProfit: "1.02",
	// 				stopLoss: currentPrice + 3.34,
	// 				stopOut: 163.5,
	// 				symbol: "BOOM1000",
	// 				symbolName: "Boom 1000 Index",
	// 				takeProfit: null,
	// 				tpProfit: currentPrice - 3.34,
	// 				tradeAmount: "2.45",
	// 				type: "Buy",
	// 				userId: 2,
	// 			};

	// 			console.log("CONTRACT BODY", body);
	// 			socket.emit("buyMultUpMultDn", body);
	// 		}
	// 	}

	// 	// detectCross1M();

	// 	if (smaData1M.length > candlesQty) {
	// 		smaData1M.shift();
	// 	}
	// }, 60000);

	const calculateRSI = (data, periods = 9) => {
		let gains = [];
		let losses = [];

		for (let i = 1; i < data.length; i++) {
			const change = data[i].price - data[i - 1].price;
			gains.push(change >= 0 ? change : 0);
			losses.push(change < 0 ? Math.abs(change) : 0);
		}
		const avgGain = gains.slice(-periods).reduce((a, b) => a + b, 0) / periods;
		const avgLoss = losses.slice(-periods).reduce((a, b) => a + b, 0) / periods;
		const rs = avgGain / avgLoss;
		const rsi = 100 - 100 / (1 + rs);

		return rsi;
	};

	const trackDrawdown = (profit) => {
		runningBalance += profit;

		if (runningBalance < lowestBalance) {
			lowestBalance = runningBalance;
			maxDrawdown = Math.abs(lowestBalance);
		}

		return maxDrawdown;
	};

	const calculateSMA = (period, data) => {
		return data.reduce((acc, val, index, arr) => {
			if (index < period - 1) return [...acc, val.close];
			const slice = arr.slice(index - period + 1, index + 1);
			const sum = slice.reduce((sum, item) => sum + item.close, 0);
			return [...acc, sum / period];
		}, []);
	};

	const processData1M = (data) => {
		sma5_1M = calculateSMA(5, data);
		sma9_1M = calculateSMA(9, data);
		sma200_1M = calculateSMA(200, data);

		smaData1M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			sma5: sma5_1M[i],
			sma9: sma9_1M[i],
			sma200: sma200_1M[i],
		}));

		detectCross1M();
	};

	const detectCross1M = () => {
		const symbol = tvWidget.activeChart().symbol();
		const lastTwentyCandles = smaData1M.slice(-20);
		const priceMovements = lastTwentyCandles.map((candle) =>
			Math.abs(candle.price - (candle.open || lastTwentyCandles[lastTwentyCandles.indexOf(candle) - 1]?.price || candle.price))
		);

		const mean = priceMovements.reduce((a, b) => a + b, 0) / priceMovements.length;
		const variance = priceMovements.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / priceMovements.length;
		const stdDev = Math.sqrt(variance);
		const normalCandleSizes = priceMovements.filter((movement) => movement <= mean + 2 * stdDev);
		const averageCandleSize = normalCandleSizes.reduce((a, b) => a + b, 0) / normalCandleSizes.filter((size) => size > 0).length || 1;

		for (let i = 1; i < smaData1M.length; i++) {
			const previous = smaData1M[i - 1];
			const current = smaData1M[i];
			const rsi = calculateRSI(smaData1M.slice(0, i + 1), 9);
			console.log(previous.sma5 < previous.sma9 && current.sma5 > current.sma9 && symbol.includes("CRASH"));
			console.log(previous.sma5, previous.sma9, current.sma5, symbol.includes("CRASH"));

			if (previous.sma5 < previous.sma9 && current.sma5 > current.sma9 && symbol.includes("CRASH")) {
				if (rsi < 50) {
					const stopLoss = current.price - averageCandleSize * retCandles;
					const takeProfit = current.price + averageCandleSize * retCandles;
					console.log("RSI", stopLoss, takeProfit);

					totalCrosses++;
					cross1M = true;
					markCheck1M(tvWidget, current.time, current.price, "up");

					for (let j = i + 1; j < smaData1M.length; j++) {
						const closePrice = Number(smaData1M[j].price);

						if (closePrice <= stopLoss) {
							const candleMovements = Math.abs(current.price - closePrice) / averageCandleSize;
							const loss = candleMovements * amount;
							totalLoss += loss;
							markProfit1M(tvWidget, smaData1M[j].time, closePrice, "loss", loss);
							break;
						}

						if (closePrice >= takeProfit) {
							winningCrosses++;
							const candleMovements = Math.abs(closePrice - current.price) / averageCandleSize;
							const profit = amount; //candleMovements * amount;
							totalProfit += profit;
							markProfit1M(tvWidget, smaData1M[j].time, closePrice, "win", profit);
							break;
						}
					}
				}
			} else if (previous.sma5 > previous.sma9 && current.sma5 < current.sma9 && symbol.includes("BOOM")) {
				if (rsi > 50) {
					const stopLoss = current.price + averageCandleSize * retCandles;
					const takeProfit = current.price - averageCandleSize * retCandles;

					totalCrosses++;
					cross1M = true;
					markCheck1M(tvWidget, current.time, current.price, "down");

					for (let j = i + 1; j < smaData1M.length; j++) {
						const closePrice = Number(smaData1M[j].price);

						if (closePrice >= stopLoss) {
							const candleMovements = Math.abs(closePrice - current.price) / averageCandleSize;
							const loss = candleMovements * amount;
							totalLoss += loss;
							trackDrawdown(-loss);
							markProfit1M(tvWidget, smaData1M[j].time, closePrice, "loss", loss);
							break;
						}

						if (closePrice <= takeProfit) {
							winningCrosses++;
							const candleMovements = Math.abs(current.price - closePrice) / averageCandleSize;
							const profit = amount; //candleMovements * amount;
							totalProfit += profit;
							trackDrawdown(profit);
							markProfit1M(tvWidget, smaData1M[j].time, closePrice, "win", profit);
							break;
						}
					}
				}
			}
		}

		console.log("Final Stats - Crosses:", totalCrosses, "Wins:", winningCrosses, "Profit:", totalProfit, "Loss:", totalLoss);
		const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
		updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
	};

	const markCheck1M = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#00E6F9" : "#00E6F9";
		const cross = 0xf00d;
		const check = 0xf00c;
		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 30,
					zOrder: "top",
				},
				icon: check,
			}
		);
	};

	const markProfit1M = (tvWidget, time, price, direction, profit) => {
		const color = direction === "win" ? "green" : "red";
		const prefix = direction === "win" ? "$" : "-$";

		tvWidget.activeChart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "balloon",
				text: `${prefix}${Math.abs(profit).toFixed(2)}`,
				overrides: {
					backgroundColor: color,
					color: "white",
					fontsize: 20,
					bold: true,
					zIndex: 100,
				},
				zOrder: "top",
			}
		);
	};

	window.currentTvWidget = tvWidget;
	window.clearSpikeShield = (widget) => {
		console.log("Clearing spike shield");
		widget.activeChart().removeAllShapes();
		const statsBox = document.getElementById("statsBox");
		if (statsBox) {
			statsBox.remove();
		}
	};

	function updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss) {
		const statsBox = document.getElementById("statsBox");
		if (!statsBox) {
			const box = document.createElement("div");
			box.id = "statsBox";
			box.style.cssText = `
				position: absolute;
				bottom: 20px;
				left: 20px;
				width: 250px;
				height: 300px;
				background-color: rgba(23, 27, 38, 0.9);
				padding: 15px;
				border-radius: 8px;
				color: white;
				box-shadow: 0 0 5px #4599d9, 0 0 10px #4599d9;
				font-family: 'Orbitron', sans-serif;
				font-size: 12px;
				line-height: 1.5;
				cursor: move;
				user-select: none;
			`;
			document.body.appendChild(box);
			makeDraggable(box);
		}

		const netProfit = totalProfit - totalLoss;
		const profitColor = netProfit >= 0 ? "green" : "red";
		const days = Math.floor(candlesQty / (24 * 60));
		const remainingHours = Math.floor((candlesQty % (24 * 60)) / 60);

		document.getElementById("statsBox").innerHTML = `
		<div style="position: absolute; top: 5px; right: 10px; cursor: pointer; font-size: 16px; color: red;" 
         onclick="window.clearSpikeShield(window.currentTvWidget)">✖</div>
			<p style="font-weight: bold; color: #4599d9;">Periodo: ${days}d ${remainingHours}h (${candlesQty} velas)</p>
			<p>Cruces Totales: <span style="float: right;">${totalCrosses}</span></p>
			<p>Cruces Ganadores: <span style="float: right;">${winningCrosses}</span></p>
			<p>Tasa de Éxito: <span style="float: right;">${winRate}%</span></p>
			<p>Drawdown Máximo: <span style="float: right; color: orange;">${maxDrawdown.toFixed(2)}</span></p>
			<hr style="border: 1px solid #4599d9; margin: 8px 0;">
			<p>Ganancia Total: <span style="float: right; color: ${profitColor};">${totalProfit.toFixed(2)}</span></p>
			<p>Pérdida Total: <span style="float: right; color: red;">${totalLoss.toFixed(2)}</span></p>
			<p>Resultado Neto: <span style="float: right; color: ${profitColor};">${netProfit.toFixed(2)}</span></p>
		`;
	}

	function makeDraggable(element) {
		let pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;
		element.onmousedown = dragMouseDown;

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			element.style.top = element.offsetTop - pos2 + "px";
			element.style.left = element.offsetLeft - pos1 + "px";
		}

		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	const addEMAStudies = (tvWidget, shortPeriod = 50, longPeriod = 200) => {
		tvWidget.activeChart().createStudy(
			"Moving Average",
			false,
			false,
			{ length: longPeriod },
			{
				"Plot.color": "orange",
				"Plot.linewidth": 3,
			}
		);

		tvWidget.activeChart().createStudy(
			"Moving Average",
			false,
			false,
			{ length: shortPeriod },
			{
				"Plot.color": "green",
				"Plot.linewidth": 3,
			}
		);
	};
	// addEMAStudies(tvWidget, 5, 9);
};
