import { getLastCloses } from "../../streaming.js";

export const runVelandiaSwing = (tvWidget) => {
	console.log("RUN VELANDIA CAPITAL BOT");
	let cross1H = false;
	let cross5M = false;
	let emaData1H = []; // Almacena los valores de EMA de 1H
	let emaData15M = []; // Almacena los valores de EMA de 15M
	let emaData5M = []; // Almacena los valores de EMA de 5M
	let ema50_1H = null;
	let ema200_1H = null;
	let ema50_5M = null;
	let ema200_5M = null;
	let ema50_15M = null;
	let ema200_15M = null;
	let lastPrice = null;

	const init = async () => {
		const ohlcData1H = await getLastCloses(3600, 500);
		console.log("1H Data received:", ohlcData1H.length);

		const ohlcData15M = await getLastCloses(900, 2000);
		console.log("15M Data received:", ohlcData15M.length);

		const ohlcData5M = await getLastCloses(300, 5000);
		console.log("5M Data received:", ohlcData5M.length);

		if (ohlcData5M.length > 0) {
			await processData5M(ohlcData5M);
			await processData15M(ohlcData15M);
			await processData1H(ohlcData1H);

			// await drawEMA(tvWidget, emaData15M, "orange", "green");
		}
	};

	init();

	const calculateEMA = (period, data) => {
		let multiplier = 2 / (period + 1);
		return data.reduce((acc, val, index) => {
			if (index === 0) return [val.close];
			let ema = (val.close - acc[index - 1]) * multiplier + acc[index - 1];
			return [...acc, ema];
		}, []);
	};

	const drawEMA = (tvWidget, emaData, color50, color200) => {
		// Dibujar la EMA de 50 periodos como segmentos de línea individuales
		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema50 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema50 },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					overrides: {
						linecolor: color50,
						linewidth: 4,
					},
				}
			);
		}

		// Dibujar la EMA de 200 periodos como segmentos de línea individuales
		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema200 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema200 },
				],
				{
					shape: "trend_line",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					overrides: {
						linecolor: color200,
						linewidth: 4,
					},
				}
			);
		}
	};

	const processData1H = (data) => {
		ema50_1H = calculateEMA(50, data);
		ema200_1H = calculateEMA(200, data);

		emaData1H = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_1H[i],
			ema200: ema200_1H[i],
		}));

		detectCross1H();
	};

	const processData15M = (data) => {
		ema50_15M = calculateEMA(50, data);
		ema200_15M = calculateEMA(200, data);

		emaData15M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_15M[i],
			ema200: ema200_15M[i],
		}));
	};

	const processData5M = (data) => {
		ema50_5M = calculateEMA(50, data);
		ema200_5M = calculateEMA(200, data);

		emaData5M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_5M[i],
			ema200: ema200_5M[i],
		}));
	};

	let condition1H = {
		active: false,
		time: null,
		direction: null,
	};

	let condition15M = {
		active: false,
		time: null,
	};

	const detectCross1H = () => {
		for (let i = 1; i < emaData1H.length; i++) {
			const previous = emaData1H[i - 1];
			const current = emaData1H[i];

			if (previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				condition1H = {
					active: true,
					time: previous.time,
					direction: "up",
				};
				console.log("Primera condición activada: Cruce 1H alcista");
				markCrossH1(tvWidget, previous.time, previous.price, "up");
				monitorSecondCondition();
			} else if (previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				condition1H = {
					active: true,
					time: previous.time,
					direction: "down",
				};
				console.log("Primera condición activada: Cruce 1H bajista");
				markCrossH1(tvWidget, previous.time, previous.price, "down");
				monitorSecondCondition();
			}
		}
	};

	const monitorSecondCondition = () => {
		const endTime = condition1H.time + 24 * 60 * 60 * 1000;

		const check15M = emaData15M.filter((data) => data.time === condition1H.time);

		console.log("COND H1", condition1H);
		console.log("CHECK 15M", check15M);

		if (check15M.length > 0) {
			if (condition1H.direction === "up" && check15M[0].ema200 < check15M[0].ema50 && check15M[0].price > check15M[0].ema50) {
				condition15M = {
					active: true,
					time: check15M[0].time,
				};
				console.log("Segunda condición activada: Alineación 15M y precio arriba de EMA50");
				markCross15M(tvWidget, check15M[0].time, check15M[0].price, "up");
				monitorThirdCondition();
			} else if (condition1H.direction === "down" && check15M[0].ema200 > check15M[0].ema50 && check15M[0].price < check15M[0].ema50) {
				condition15M = {
					active: true,
					time: check15M[0].time,
				};
				console.log("Segunda condición activada: Alineación 15M y precio abajo de EMA50");
				markCross15M(tvWidget, check15M[0].time, check15M[0].price, "down");
				monitorThirdCondition();
			}
		}
	};

	const monitorThirdCondition = () => {
		const endTime = condition15M.time + 4 * 60 * 60 * 1000;
		const check5M = emaData5M.filter((data) => data.time >= condition15M.time && data.time <= endTime);

		for (const data of check5M) {
			if (condition1H.direction === "up" && data.low < data.ema50) {
				console.log("¡SEÑAL DE COMPRA! Tercera condición cumplida");
				markCrossM5(tvWidget, data.time, data.price, "trade");
				detectCross5M(data.time);
				resetConditions();
				break;
			} else if (condition1H.direction === "down" && data.high > data.ema50) {
				console.log("¡SEÑAL DE VENTA! Tercera condición cumplida");
				markCrossM5(tvWidget, data.time, data.price, "trade");
				detectCross5M(data.time);
				resetConditions();
				break;
			}
		}
	};

	const resetConditions = () => {
		condition1H = { active: false, time: null, direction: null };
		condition15M = { active: false, time: null };
	};

	const detectCross5M = (startTime) => {
		const filteredData = emaData5M.filter((data) => data.time >= startTime);

		for (let i = 1; i < filteredData.length; i++) {
			const previous = filteredData[i - 1];
			const current = filteredData[i];
			if (previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				const date = new Date(previous.time).toLocaleString();
				console.log(`Cruce al alza en 5M - Fecha: ${date}, Precio: ${previous.price}`);
				if (cross1H) {
					cross5M = true;
					markCrossM5(tvWidget, previous.time, previous.price, "up");
					checkAlignmentAndSignal(i, "up");
				}
				return;
			} else if (previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				const date = new Date(current.time).toLocaleString();
				console.log(`Cruce a la baja en 5M - Fecha: ${date}, Precio: ${current.price}`);
				if (cross1H) {
					cross5M = true;
					markCrossM5(tvWidget, previous.time, previous.price, "down");
					checkAlignmentAndSignal(i, "down");
				}
				return;
			}
		}
	};

	// Marcar cruce en el gráfico
	const markCrossH1 = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#4CAF50" : "#FF5252"; // Verde para alza, rojo para baja
		const cross = 0xf00d;
		const check = 0xf00d;
		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 30,
					zOrder: "top",
				},
				icon: cross,
			}
		);
	};

	const markCross15M = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#90EE90" : "yellow"; // Verde para alza, rojo para baja
		const cross = 0xf00d;
		const check = 0xf00c;
		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 20,
					zOrder: "top",
				},
				icon: check,
			}
		);
	};

	// Marcar cruce en el gráfico
	const markCrossM5 = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#00E6F9" : direction === "trade" ? "#fff" : "#D631ED";

		const cross = 0xf00d;
		const check = 0xf00d;
		const flag = 0xf087;

		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 30,
					zOrder: "top",
				},
				icon: direction === "trade" ? flag : cross,
			}
		);
	};

	// Verificar alineación y mandar señal si se cumple
	const checkAlignmentAndSignal = (index, direction) => {
		if (cross1H && cross5M) {
			// let ema50 = emaData5M[index].ema50;
			// let ema200 = emaData5M[index].ema200;
			// let price = emaData5M[index].price;

			// if (direction === "up" && ema200 < ema50 && price < ema50) {
			// 	console.log("Señal de compra: Alineación al alza y precio debajo de EMA 50");
			// } else if (direction === "down" && ema200 > ema50 && price > ema50) {
			// 	console.log("Señal de venta: Alineación a la baja y precio arriba de EMA 50");
			// }

			// Resetear cruces después de señal
			cross1H = false;
			cross5M = false;
		}
	};

	const addEMAStudies = (tvWidget, shortPeriod = 50, longPeriod = 200) => {
		tvWidget.activeChart().createStudy(
			"Moving Average Exponential",
			false,
			false,
			{ length: longPeriod },
			{
				"Plot.color": "orange",
				"Plot.linewidth": 3,
			}
		);

		tvWidget.activeChart().createStudy(
			"Moving Average Exponential",
			false,
			false,
			{ length: shortPeriod },
			{
				"Plot.color": "green",
				"Plot.linewidth": 3,
			}
		);
	};
	// addEMAStudies(tvWidget, 50, 200);
};
