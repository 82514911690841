import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { toast } from "react-toastify";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";

const Payments = ({ openPayments, onClosePayments, service, price }) => {
	const [errors, setErrors] = useState({});
	const [paymentMethod, setPaymentMethod] = useState("Tarjeta");
	const [cardDetails, setCardDetails] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focused: "",
	});
	const [cryptoPaymentInfo, setCryptoPaymentInfo] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (openPayments) {
			console.log("service:", service);
			console.log("price:", price);
		}
	}, [openPayments]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log("handleInputChange", e.target.name, e.target.value);
		setCardDetails((prevDetails) => ({
			...prevDetails,
			[name]: value,
		}));
	};

	const handleInputFocus = (e) => {
		setCardDetails((prevDetails) => ({
			...prevDetails,
			focused: e.target.name,
		}));
	};

	const handlePaymentMethodChange = (method) => {
		setPaymentMethod(method);
		if (method === "Crypto") {
			handleCryptoPayment();
		}
	};

	const validateCardDetails = () => {
		console.log("VALIDATE CARD DETAILS");

		let tempErrors = {};

		// Validate card number (basic check for length and numeric)
		if (!cardDetails.number || cardDetails.number.length < 13 || cardDetails.number.length > 19 || !/^\d+$/.test(cardDetails.number)) {
			tempErrors.number = "Número de tarjeta inválido";
		}

		// Validate name (minimum 5 characters)
		if (!cardDetails.name || cardDetails.name.length < 5) {
			tempErrors.name = "El nombre debe tener al menos 5 caracteres";
		}

		// Validate expiry date (MM/YY format)
		const month = cardDetails.expiry.substring(0, 2);
		const year = cardDetails.expiry.substring(2, 4);
		const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
		const currentMonth = new Date().getMonth() + 1;
		if (
			!month ||
			!year ||
			isNaN(month) ||
			isNaN(year) ||
			month < 1 ||
			month > 12 ||
			year < currentYear ||
			(year == currentYear && month < currentMonth)
		) {
			tempErrors.expiry = "Fecha de expiración inválida";
		}

		// Validate CVV (3 or 4 digits)
		if (!cardDetails.cvc || !/^\d{3,4}$/.test(cardDetails.cvc)) {
			tempErrors.cvc = "CVV inválido";
		}

		console.log("ERRORS", tempErrors);

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmitPayment = () => {
		if (!validateCardDetails()) {
			toast.error("Algunos de los campo estan con errores", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}

		const payload = {
			userId: user.id,
			amount: price,
			chartType: service,
			cardDetails: cardDetails,
			copyTo: traderToCopy.replace("GRX", ""),
		};
		axios
			.post("https://mitserver.app:9005/purchase", payload)
			.then((response) => {
				if (response.data.ok) {
					toast.success(response.data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					setShowCard(false);
				} else {
					toast.error(response.data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					setShowCard(false);
				}
				handleSearch();
			})
			.catch((error) => {});
	};

	const handleCryptoPayment = async () => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));
			const responseLogin = await axios.post("https://mitserver.app:9000/login", {
				username: "marketView",
				password: "GrksVlt2020@",
			});
			const { token } = responseLogin.data;
			const data = {
				userId: user.id,
				service: "drawings",
				description: "Mensualidad Drawings",
				amount: price,
				currency: "USD",
				frequency: "monthly",
			};
			const responseCharge = await axios.post("https://mitserver.app:9000/chargeCrypto", data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (responseCharge.data) {
				setCryptoPaymentInfo(responseCharge.data);
			}
		} catch (error) {
			const errorResponse = error.response.data;
			console.error("Error processing crypto payment:", errorResponse.details.message);
			toast.error(errorResponse.details.message, {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{openPayments && (
				<>
					<Box
						onClick={onClosePayments}
						sx={{
							position: "fixed",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 9998,
						}}>
						<Box
							onClick={(e) => e.stopPropagation()}
							sx={{
								position: "fixed",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
								marginTop: 4,
								padding: 2,
								backgroundColor: "#1c2331",
								borderRadius: "20px",
								boxShadow: "0 0 15px #f74712, 0 0 10px #f74712",
								maxWidth: "500px",
								width: "90%",
								maxHeight: "90vh",
								overflowY: "auto",
							}}>
							<Typography
								sx={{ fontFamily: "'Orbitron', sans-serif", fontSize: 14, p: 1, textAlign: "center" }}
								color="text.secondary"
								gutterBottom>
								Suscripción Mensual de <span style={{ fontWeight: "bold", color: "white" }}>{service}</span> por $
								<span style={{ fontSize: 14, fontWeight: "bold", color: "white" }}>{price}</span>
							</Typography>
							<Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
								<Button
									variant={paymentMethod === "Tarjeta" ? "contained" : "outlined"}
									onClick={() => handlePaymentMethodChange("Tarjeta")}
									sx={{
										mx: 1,
										backgroundColor: paymentMethod === "Tarjeta" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										"&:hover": {
											backgroundColor: paymentMethod === "Tarjeta" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										},
									}}>
									Tarjeta
								</Button>
								<Button
									variant={paymentMethod === "Crypto" ? "contained" : "outlined"}
									onClick={() => handlePaymentMethodChange("Crypto")}
									sx={{
										mx: 1,
										backgroundColor: paymentMethod === "Crypto" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										"&:hover": {
											backgroundColor: paymentMethod === "Crypto" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										},
									}}>
									Crypto
								</Button>
							</Box>
							{paymentMethod === "Tarjeta" && (
								<>
									<Cards
										number={cardDetails.number}
										name={cardDetails.name}
										expiry={cardDetails.expiry}
										cvc={cardDetails.cvc}
										focused={cardDetails.focused}
									/>
									<TextField
										label="Número de Tarjeta"
										variant="outlined"
										margin="dense"
										name="number"
										value={cardDetails.number}
										onChange={handleInputChange}
										onFocus={handleInputFocus}
										error={!!errors.number}
										helperText={errors.number}
										sx={{
											width: "100%",
											mb: 1,
											"& .MuiOutlinedInput-root": {
												transition: "all 0.2s ease-in-out",
												"& fieldset": {
													borderColor: "rgba(255, 255, 255, 0.23)",
												},
												"&:hover fieldset": {
													borderColor: "#4599d9",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#4599d9",
												},
											},
											"& .MuiInputBase-input": {
												color: "#fff",
												padding: "14px",
											},
											"& .MuiInputLabel-root": {
												color: "rgba(255, 255, 255, 0.7)",
												"&.Mui-focused": {
													color: "#4599d9",
												},
												transform: "translate(14px, 16px) scale(1)",
												"&.Mui-focused, &.MuiFormLabel-filled": {
													transform: "translate(14px, -9px) scale(0.75)",
												},
											},
										}}
										InputProps={{
											style: { color: "#fff", fontSize: "1rem" },
										}}
										InputLabelProps={{
											style: { color: "#fff", fontSize: "0.9rem" },
										}}
									/>
									<TextField
										label="Nombre en la Tarjeta"
										variant="outlined"
										margin="dense"
										name="name"
										value={cardDetails.name}
										onChange={handleInputChange}
										onFocus={handleInputFocus}
										error={!!errors.name}
										helperText={errors.name}
										sx={{
											width: "100%",
											mb: 1,
											"& .MuiOutlinedInput-root": {
												transition: "all 0.2s ease-in-out",
												"& fieldset": {
													borderColor: "rgba(255, 255, 255, 0.23)",
												},
												"&:hover fieldset": {
													borderColor: "#4599d9",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#4599d9",
												},
											},
											"& .MuiInputBase-input": {
												color: "#fff",
												padding: "14px",
											},
											"& .MuiInputLabel-root": {
												color: "rgba(255, 255, 255, 0.7)",
												"&.Mui-focused": {
													color: "#4599d9",
												},
												transform: "translate(14px, 16px) scale(1)",
												"&.Mui-focused, &.MuiFormLabel-filled": {
													transform: "translate(14px, -9px) scale(0.75)",
												},
											},
										}}
										InputProps={{
											style: { color: "#fff", fontSize: "1rem" },
										}}
										InputLabelProps={{
											style: { color: "#fff", fontSize: "0.9rem" },
										}}
									/>
									<Box sx={{ display: "flex", gap: 2 }}>
										<TextField
											label="Fecha de Expiración"
											variant="outlined"
											margin="dense"
											name="expiry"
											value={cardDetails.expiry}
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											error={!!errors.expiry}
											helperText={errors.expiry}
											sx={{
												width: "80%",
												mb: 1,
												"& .MuiOutlinedInput-root": {
													transition: "all 0.2s ease-in-out",
													"& fieldset": {
														borderColor: "rgba(255, 255, 255, 0.23)",
													},
													"&:hover fieldset": {
														borderColor: "#4599d9",
													},
													"&.Mui-focused fieldset": {
														borderColor: "#4599d9",
													},
												},
												"& .MuiInputBase-input": {
													color: "#fff",
													padding: "14px",
												},
												"& .MuiInputLabel-root": {
													color: "rgba(255, 255, 255, 0.7)",
													"&.Mui-focused": {
														color: "#4599d9",
													},
													transform: "translate(14px, 16px) scale(1)",
													"&.Mui-focused, &.MuiFormLabel-filled": {
														transform: "translate(14px, -9px) scale(0.75)",
													},
												},
											}}
											InputProps={{
												style: { color: "#fff", fontSize: "1rem" },
											}}
											InputLabelProps={{
												style: { color: "#fff", fontSize: "0.9rem" },
											}}
										/>
										<TextField
											label="CVV"
											variant="outlined"
											margin="dense"
											name="cvc"
											value={cardDetails.cvc}
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											error={!!errors.cvc}
											helperText={errors.cvc}
											sx={{
												width: "80%",
												mb: 1,
												"& .MuiOutlinedInput-root": {
													transition: "all 0.2s ease-in-out",
													"& fieldset": {
														borderColor: "rgba(255, 255, 255, 0.23)",
													},
													"&:hover fieldset": {
														borderColor: "#4599d9",
													},
													"&.Mui-focused fieldset": {
														borderColor: "#4599d9",
													},
												},
												"& .MuiInputBase-input": {
													color: "#fff",
													padding: "14px",
												},
												"& .MuiInputLabel-root": {
													color: "rgba(255, 255, 255, 0.7)",
													"&.Mui-focused": {
														color: "#4599d9",
													},
													transform: "translate(14px, 16px) scale(1)",
													"&.Mui-focused, &.MuiFormLabel-filled": {
														transform: "translate(14px, -9px) scale(0.75)",
													},
												},
											}}
											InputProps={{
												style: { color: "#fff", fontSize: "1rem" },
											}}
											InputLabelProps={{
												style: { color: "#fff", fontSize: "0.9rem" },
											}}
										/>
									</Box>
									<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
										<Button
											variant="contained"
											color="primary"
											onClick={handleSubmitPayment}
											sx={{
												marginTop: 2,
												backgroundColor: "#4599d9",
												width: "150px",
												color: "#fff",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												padding: "8px 16px",
												fontSize: "0.8rem",
												cursor: "pointer",
												transition: "all 0.3s ease",
												textShadow: "0 0 10px #fff",
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: "#3d87c3",
													boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												},
											}}>
											Pagar
										</Button>
									</Box>
								</>
							)}
							{loading ? (
								<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
									<CircularProgress
										sx={{
											color: "#4599d9",
											"& .MuiCircularProgress-svg": {
												filter: "drop-shadow(0 0 5px #4599d9)",
											},
											width: "40px !important",
											height: "40px !important",
										}}
									/>
								</Box>
							) : paymentMethod === "Crypto" && cryptoPaymentInfo ? (
								<Box sx={{ textAlign: "center", mt: 2 }}>
									<Typography sx={{ color: "#fff", mb: 1 }}>
										Enviar {cryptoPaymentInfo.pay_amount} {cryptoPaymentInfo.pay_currency.toUpperCase()} a la dirección:
									</Typography>
									<Typography sx={{ color: "#fff", mb: 2, wordBreak: "break-all" }}>{cryptoPaymentInfo.pay_address}</Typography>
									<QRCodeCanvas value={`${cryptoPaymentInfo.pay_address}`} size={256} />

									{/* Additional Information */}
									<Typography sx={{ color: "#fff", mt: 2 }}>Estado del Pago: {cryptoPaymentInfo.payment_status}</Typography>
									<Typography sx={{ color: "#fff" }}>ID del Pago: {cryptoPaymentInfo.payment_id}</Typography>
									<Typography sx={{ color: "#fff" }}>Descripción del Pedido: {cryptoPaymentInfo.order_description}</Typography>
									<Typography sx={{ color: "#fff" }}>
										Fecha de Creación: {new Date(cryptoPaymentInfo.created_at).toLocaleString()}
									</Typography>
									<Typography sx={{ color: "#fff" }}>
										Válido Hasta: {new Date(cryptoPaymentInfo.valid_until).toLocaleString()}
									</Typography>
									<Typography sx={{ color: "#fff" }}>Red: {cryptoPaymentInfo.network}</Typography>
								</Box>
							) : null}
							<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
								<Button
									variant="contained"
									onClick={onClosePayments}
									sx={{
										mt: 2,
										width: "150px",
										backgroundColor: "#f74712",
										color: "#fff",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										padding: "8px 16px",
										fontSize: "0.8rem",
										cursor: "pointer",
										transition: "all 0.3s ease",
										textShadow: "0 0 10px #fff",
										boxShadow: "0 0 5px #f74712, 0 0 10px #f74712",
										"&:hover": {
											backgroundColor: "#d93d0d",
											boxShadow: "0 0 5px #f74712, 0 0 10px #f74712",
										},
									}}>
									Close
								</Button>
							</Box>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default Payments;
