import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	List,
	ListItem,
	ListItemText,
	Typography,
	Grid,
	ListItemButton,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { store } from "../../../../store/store.js";
import axios from "axios";

import { runLogaritmic } from "./logaritmic";
import { runtwinsStrike } from "./twinsStrike";
import { runSpikeShield } from "./spikeShield.js";
import { runAccumulators } from "./mlAccumulators";
import { runTouchNoTouch } from "./mlTouchNoTouch.js";
import { runHigherLower } from "./mlHigherLower.js";
import { runRiseFall } from "./mlRiseFall";
import { runVelandiaScalper } from "./velandiaScalper.js";
import { runVelandiaSwing } from "./velandiaSwing.js";

const BotsPage = ({ open, onClose, onOpen }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const tvWidget = store((state) => state.chart);
	const profitTicks = localStorage.getItem("profitTick");
	const chartType = localStorage.getItem("chartType");
	const optionType = localStorage.getItem("optionType");
	const ticks = localStorage.getItem("sliderValue");
	const localBarrier = localStorage.getItem("barrier");
	const [selectedBot, setSelectedBot] = useState("");
	const [windowSize, setWindowSize] = useState(80);
	const [predictionTicks, setPredictionTicks] = useState("");
	const [barrier, setBarrier] = useState(localBarrier || "");
	const [epochs, setEpochs] = useState(3);
	const [mins, setMins] = useState("1440");
	const [slRetCandles, setSLRetCandles] = useState(4);
	const [tpRetCandles, setTPRetCandles] = useState(8);
	const [strategies, setStrategies] = useState([]);
	const [amount, setAmount] = useState("");

	useEffect(() => {
		const localMins = localStorage.getItem("mins");
		const slRetCandles = localStorage.getItem("slRetCandles");
		const tpRetCandles = localStorage.getItem("tpRetCandles");
		const localAmount = localStorage.getItem("retCandlesAmount");

		if (localMins) setMins(localMins);
		if (slRetCandles) setSLRetCandles(parseInt(slRetCandles));
		if (tpRetCandles) setTPRetCandles(parseInt(tpRetCandles));
		if (localAmount) setAmount(parseInt(localAmount));
	}, []);

	useEffect(() => {
		setPredictionTicks(profitTicks ? profitTicks : 5);
	}, [profitTicks]);

	useEffect(() => {
		const fetchStrategies = async () => {
			const response = await fetch("https://mitserver.app:9005/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					table: "strategies",
				}),
			});

			const data = await response.json();
			const filteredStrategies = data.filter((strategy) => strategy.chart === chartType);
			setStrategies(filteredStrategies);
		};

		fetchStrategies();
	}, [chartType]);

	const handleBotSelection = (selectedBot) => {
		setSelectedBot(selectedBot);
		switch (selectedBot.name) {
			case "Logaritmic":
				runLogaritmic(tvWidget);
				break;
			case "TwinStrike":
				runtwinsStrike(tvWidget);
				onClose(true);
				break;
			case "SpikeShield":
				runSpikeShield(tvWidget, mins, slRetCandles, amount);
				onClose(true);
				break;
			case "Accumulators":
				runAccumulators(tvWidget, epochs, optionType === "accumulators" ? predictionTicks : ticks, windowSize);
				onClose(true);
				break;
			case "Touch No Touch":
				runTouchNoTouch(tvWidget, epochs, ticks, windowSize);
				onClose(true);
				break;
			case "Higher Lower":
				runHigherLower(tvWidget, epochs, ticks, windowSize);
				onClose(true);
				break;
			case "Rise Fall":
				runRiseFall(tvWidget, epochs, ticks, windowSize);
				onClose(true);
				break;
			case "Velandia Scalper":
				runVelandiaScalper(tvWidget, mins, slRetCandles, tpRetCandles, amount);
				onClose(true);
				break;
			case "Velandia Swing":
				runVelandiaSwing(tvWidget, mins, slRetCandles, tpRetCandles, amount);
				onClose(true);
				break;
			default:
				break;
		}
	};

	const closeModal = () => {
		setSelectedBot(null);
		onClose(false);
	};

	return (
		<div>
			<Modal open={open} onClose={closeModal}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "75%",
						height: "75%",
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						display: "flex",
						borderRadius: "30px",
						boxShadow: "0 0 10px #4599d9, 0 0 40px #4599d9",
					}}>
					<Typography
						variant="h4"
						sx={{
							color: "#fff",
							fontFamily: "'Orbitron', sans-serif",
							fontSize: "2rem",
							fontWeight: 700,
							textTransform: "uppercase",
							letterSpacing: "2px",
							position: "absolute",
							right: "35%",
						}}>
						Estrategias
					</Typography>
					<Box
						sx={{
							width: "45%",
							mr: 2,
							backgroundColor: "transparent",
							overflow: "auto",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<List sx={{ padding: 0 }}>
							{strategies.map((strategy) => (
								<ListItem
									key={strategy.id}
									sx={{
										mb: 4,
										borderRadius: 2,
										backgroundColor: selectedBot === strategy.name ? "#f74712" : "#2a2e39",
										boxShadow:
											selectedBot === strategy.name
												? "0 0 10px #f74712, 0 0 30px #f74712"
												: "0 0 5px #f74712, 0 0 15px #f74712",
										transition: "all 0.3s ease",
										"&:hover": {
											transform: "scale(1.05)",
											boxShadow: "0 0 10px #f74712, 0 0 30px #f74712",
										},
									}}>
									<ListItemButton
										onClick={(s) => {
											setSelectedBot(strategy);
										}}>
										<ListItemText
											primary={strategy.name}
											secondary={
												<Typography sx={{ color: "#fff" }}>
													{strategy.userName} - ${strategy.price}
												</Typography>
											}
											sx={{
												color: "#fff",
												textAlign: "center",
												"& .MuiListItemText-primary": {
													fontFamily: "'Orbitron', sans-serif",
													fontSize: "1.5rem",
													fontWeight: 700,
													textTransform: "uppercase",
													letterSpacing: "2px",
												},
											}}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Box>
					<Box
						sx={{
							width: "70%",
							backgroundColor: "tranparent",
							padding: 3,
							overflow: "auto",
							pt: 10,
						}}>
						{selectedBot && (
							<>
								<Grid container>
									<Grid item xs={12}>
										<Box display="flex" justifyContent="center" width="100%">
											<img
												src={String(selectedBot.image)}
												alt={selectedBot.image}
												style={{
													width: "95%",
													borderRadius: 8,
													boxShadow: "0 0 5px #4599d9, 0 0 20px #4599d9",
												}}
											/>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Typography
											variant="body1"
											sx={{
												color: "#fff",
												textAlign: "center",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "1rem",
												fontWeight: 500,
												letterSpacing: "1px",
												mt: 2,
											}}>
											{selectedBot.description}
										</Typography>
									</Grid>
									{chartType === "Opciones" ? (
										<Box sx={{ display: "flex", flexDirection: "row", gap: 2, marginBottom: 2, mt: 2 }}>
											<TextField
												label="Window Size"
												type="number"
												value={windowSize}
												onChange={(e) => setWindowSize(Number(e.target.value))}
												variant="outlined"
												size="small"
											/>
											<TextField
												label="Predecir Ticks"
												type="number"
												value={predictionTicks}
												onChange={(e) => setPredictionTicks(Number(e.target.value))}
												variant="outlined"
												size="small"
												disabled={optionType === "accumulators" ? false : true}
											/>
											{optionType !== "accumulators" && (
												<TextField
													label="Barrera"
													type="text"
													value={barrier}
													onChange={async (e) => {
														setBarrier(Number(e.target.value));
														await localStorage.setItem("barrier", e, target.value);
													}}
													variant="outlined"
													size="small"
													disabled={true}
												/>
											)}
											<TextField
												label="Epochs"
												type="number"
												value={epochs}
												onChange={(e) => setEpochs(Number(e.target.value))}
												variant="outlined"
												size="small"
											/>
										</Box>
									) : (
										<Box sx={{ display: "flex", flexDirection: "row", gap: 2, marginBottom: 2, mt: 2 }}>
											<TextField
												label="Cantidad Minutos"
												type="text"
												value={mins}
												onChange={async (e) => {
													setMins(Number(e.target.value));
													await localStorage.setItem("mins", e.target.value);
												}}
												variant="outlined"
												size="small"
											/>

											<TextField
												label="Velas SL"
												type="number"
												value={slRetCandles}
												onChange={async (e) => {
													setSLRetCandles(Number(e.target.value));
													await localStorage.setItem("slRetCandles", e.target.value);
												}}
												variant="outlined"
												size="small"
											/>
											<TextField
												label="Velas TP"
												type="number"
												value={tpRetCandles}
												onChange={async (e) => {
													setTPRetCandles(Number(e.target.value));
													await localStorage.setItem("tpRetCandles", e.target.value);
												}}
												variant="outlined"
												size="small"
											/>
											<TextField
												label="Monto en Riesgo"
												type="number"
												value={amount}
												onChange={async (e) => {
													setAmount(Number(e.target.value));
													await localStorage.setItem("retCandlesAmount", e.target.value);
												}}
												variant="outlined"
												size="small"
											/>
										</Box>
									)}
									<Box display="flex" justifyContent="center" width="100%">
										{user.paidOptions.includes("Estrategias") ? (
											<Button
												variant="contained"
												onClick={() => handleBotSelection(selectedBot)}
												sx={{
													mt: 2,
													color: "#fff",
													fontFamily: "'Orbitron', sans-serif",
													fontSize: "1.2rem",
													fontWeight: 700,
													textTransform: "uppercase",
													letterSpacing: "1px",
													backgroundColor: "#f74712",
													boxShadow: "0 0 5px #f74712, 0 0 15px #f74712",
													padding: "12px 24px",
													"&:hover": {
														backgroundColor: "#d93d0d",
													},
												}}>
												Activar
											</Button>
										) : (
											<Button
												variant="contained"
												onClick={() => {
													onClose(false);
													onOpen(true);
												}}
												sx={{
													mt: 2,
													color: "#fff",
													fontFamily: "'Orbitron', sans-serif",
													fontSize: "1.2rem",
													fontWeight: 700,
													textTransform: "uppercase",
													letterSpacing: "1px",
													backgroundColor: "#4599d9",
													boxShadow: "0 0 5px #4599d9, 0 0 15px #4599d9",
													padding: "12px 24px",
													"&:hover": {
														backgroundColor: "#3d87c3",
													},
												}}>
												Comprar
											</Button>
										)}
									</Box>
								</Grid>
							</>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default BotsPage;
