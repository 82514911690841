import { useEffect, useState, useRef } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/CancelPresentation";
import socket from "../../../../socket/Socket.js";
import { store } from "../../../../store/store.js";
import { setSymbolStorage } from "../../streaming";
import { toast } from "react-toastify";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";

export function OpeningPositionsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const chartType = localStorage.getItem("chartType");
	const openPositions = store((state) => state.openPositions);
	const derivWS = store((state) => state.derivWS);
	const balance = store((state) => state.balance);
	const [reports, setReports] = useState([]);
	const [equity, setEquity] = useState(0);

	const reportsRef = useRef([]);
	const equityRef = useRef(0);
	const subscriptionsRef = useRef({});

	const [subscriptions, setSubscriptions] = useState([]);

	useEffect(() => {
		if (!selectedAccount || !derivWS) return;
		cleanupSubscriptions();

		// if (chartType === "Multiplicadores") {
		getToken();
		// }
		derivWS.addEventListener("message", handleData);
		derivWS.addEventListener("error", handleError);

		return () => {
			cleanupSubscriptions();
		};
	}, [openPositions]);

	const getToken = async () => {
		const maxRetries = 5;
		const retryDelay = 2000;
		let retryCount = 0;

		const tryGetToken = async () => {
			try {
				const selectedAcc = store.getState().selectedAccount || JSON.parse(localStorage.getItem("selectedAccount"));
				const payout = {
					userId: selectedAcc.userId,
					account: selectedAcc.account,
				};
				const response = await axios.post("https://mitserver.app:9005/getToken", payout);

				if (response.status === 200) {
					const accountsData = response.data.data;
					const sendMessage = JSON.stringify({ authorize: accountsData.token, passthrough: { history: true } });
					console.log("sendMessage", sendMessage);

					if (derivWS.readyState === WebSocket.OPEN) {
						derivWS.send(sendMessage);
					} else {
						derivWS.onopen = () => {
							console.log("MESS", sendMessage);
						};
					}
					return true;
				}
			} catch (error) {
				retryCount++;
				if (retryCount < maxRetries) {
					await new Promise((resolve) => setTimeout(resolve, retryDelay));
					return tryGetToken();
				}
				toast.error(`Network connection issue. Attempt ${retryCount} of ${maxRetries}`, {
					position: "top-right",
					autoClose: 2000,
					theme: "dark",
					progressStyle: { background: "#f74712" },
				});
				return false;
			}
		};

		return tryGetToken();
	};

	const handleData = async (event) => {
		const msgData = JSON.parse(event.data);
		const msgType = msgData.msg_type;

		if (msgType === "proposal_open_contract") {
			if (chartType === "Opciones") {
				const multipliersId = await localStorage.getItem("multiplierSubsId");
				if (multipliersId) {
					derivWS.send(
						JSON.stringify({
							forget: multipliersId,
						})
					);
					await localStorage.setItem("multiplierSubsId", null);
				}
				const position = msgData.proposal_open_contract;
				if (!position) {
					return;
				}

				setReports((prevReports) => {
					let updatedReports = [...prevReports];
					const existingIndex = updatedReports.findIndex((report) => report.contract_id === position.contract_id);

					if (position.status === "open") {
						if (existingIndex !== -1) {
							updatedReports[existingIndex] = position;
						} else {
							updatedReports.push(position);
						}
					} else if (existingIndex !== -1) {
						updatedReports.splice(existingIndex, 1);
					}

					return updatedReports;
				});
				return;
			}

			const echoReq = msgData.echo_req;
			if (echoReq.passthrough && echoReq.passthrough.history) {
				if (!msgData.proposal_open_contract || !Object.keys(msgData.proposal_open_contract).length) {
					return;
				}
				const proposalData = msgData.proposal_open_contract;
				subscriptionsRef.current[proposalData.id] = proposalData;
				localStorage.setItem("multiplierSubsId", proposalData.id);

				setReports((prevReports) => {
					const updatedReports = prevReports.filter(
						(report) => report.contract_id !== proposalData.contract_id || proposalData.status !== "sold"
					);
					const reportIndex = updatedReports.findIndex((report) => report.contract_id === proposalData.contract_id);
					if (reportIndex !== -1) {
						updatedReports[reportIndex] = {
							...updatedReports[reportIndex],
							...proposalData,
						};
					} else if (proposalData.status !== "sold") {
						updatedReports.push(proposalData);
					}

					return updatedReports;
				});
			}
		}
	};

	const cleanupSubscriptions = () => {
		Object.keys(subscriptionsRef.current).forEach((subscriptionId) => {
			const forgetMessage = JSON.stringify({
				forget: subscriptionId,
			});

			if (derivWS.readyState === WebSocket.OPEN) {
				derivWS.send(forgetMessage);
			}
		});
		setReports([]);
		subscriptionsRef.current = {};
	};

	const handleError = (error) => {
		toast.error("Error en la conexión WebSocket.");
	};

	const cleanup = () => {
		if (derivWS) {
			derivWS.removeEventListener("message", handleData);
			derivWS.removeEventListener("error", handleError);
			Object.values(subscriptionsRef.current).forEach((subscriptionId) => {
				derivWS.send(JSON.stringify({ forget: subscriptionId }));
			});
			subscriptionsRef.current = {};
		}
	};

	const handleDelete = (row) => {
		const removeData = {
			contractId: row.contract_id,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
			symbol: row.underlying,
			symbolName: row.display_name,
		};
		socket.emit("sellContract", removeData);
	};

	const rows = [...reports];
	const totalProfit = reports.reduce((acc, report) => acc + parseFloat(report.profit || 0), 0).toFixed(2);

	const handleCellClick = async (row) => {
		row.symbol = row.underlying;
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		const tvWidget = store.getState().chart;
		const symbolReady = await setSymbolStorage(row);
		if (tvWidget && tvWidget.chart) {
			await tvWidget.chart().setSymbol(row.display_name, tvWidget.activeChart().resolution());
			let allPos = {
				userId: selectedAccount.userId,
				account: selectedAccount.account,
			};
			socket.emit("allLines", allPos);
		}
	};

	const handleDeleteClick = (event, row) => {
		event.stopPropagation(); // Evita que se propague el evento de clic a la fila
		handleDelete(row);
	};

	return (
		<Box sx={{ width: "100%", backgroundColor: "#161a25", padding: 1, position: "relative" }}>
			<div
				onClick={getToken}
				style={{
					position: "absolute",
					top: "15px",
					right: "10px",
					cursor: "pointer",
					color: "#fff",
					zIndex: 1000,
				}}>
				<RefreshIcon style={{ fontSize: "24px" }} />
			</div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(69, 153, 217, 0.2)",
					padding: "8px 15px",
					borderRadius: "8px",
					color: "#eaeaea",
				}}>
				<Box sx={{ flex: 0.4 }}>Fecha Inicio</Box>
				<Box sx={{ flex: 0.4 }}>Tipo</Box>
				<Box sx={{ flex: 0.3 }}>Monto</Box>
				<Box sx={{ flex: 0.3 }}>Multiplicador</Box>
				<Box sx={{ flex: 0.4 }}>Símbolo</Box>
				{chartType !== "Multiplicadores" && (
					<>
						<Box sx={{ flex: 0.3 }}>Stop Loss</Box>
						<Box sx={{ flex: 0.3 }}>Take Profit</Box>
					</>
				)}
				<Box sx={{ flex: 0.3 }}>Precio Entrada</Box>
				<Box sx={{ flex: 0.3 }}>Profit</Box>
				<Box sx={{ flex: 0.15, textAlign: "left" }}>Acciones</Box>
			</Box>

			{/* Contenedor con scroll para filas */}
			<Box
				sx={{
					maxHeight: "400px", // Ajusta esto a la altura deseada
					overflowY: "auto",
					padding: "15px",
				}}>
				{rows.map((row) => (
					<Box
						key={row.contract_id}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: row.id === "total" ? "rgba(113, 121, 126, 0.5)" : "rgba(23, 27, 38, 0.9)",
							padding: "6px 15px", // Ajustar altura
							borderRadius: "8px",
							boxShadow: "0px 3px 5px rgba(69, 153, 217, 0.3)",
							color: "#fff",
							cursor: "pointer",
							marginBottom: "6px", // Ajustar espacio entre filas
							transition: "background-color 0.3s ease",
							"&:hover": {
								backgroundColor: "rgba(69, 153, 217, 0.2)",
							},
						}}
						onClick={() => handleCellClick(row)}>
						<Box sx={{ flex: 0.4 }}>{new Date(row.date_start * 1000).toLocaleString()}</Box>
						<Box sx={{ flex: 0.4 }}>{row.contract_type}</Box>
						<Box sx={{ flex: 0.3 }}>{row.buy_price}</Box>
						<Box sx={{ flex: 0.3 }}>{row.multiplier}</Box>
						<Box sx={{ flex: 0.4 }}>{row.display_name}</Box>
						{chartType !== "Multiplicadores" && (
							<>
								<Box sx={{ flex: 0.3 }}>{row.stopLoss}</Box>
								<Box sx={{ flex: 0.3 }}>{row.takeProfit}</Box>
							</>
						)}
						<Box sx={{ flex: 0.3 }}>{row.entry_tick}</Box>
						<Box sx={{ flex: 0.3, color: row.profit > 0 ? "#2eb62e" : row.profit < 0 ? "#f74712" : "inherit" }}>{row.profit}</Box>
						<Box sx={{ flex: 0.15, textAlign: "center" }}>
							{row.id !== "total" && (
								<Tooltip title="Cerrar">
									<IconButton onClick={(event) => handleDeleteClick(event, row)} color="error" size="small">
										<DeleteIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</Box>
				))}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					backgroundColor: "rgba(113, 121, 126, 0.7)",
					padding: "10px 15px",
					borderRadius: "8px",
					marginTop: "16px",
					color: "#fff",
					fontWeight: "bold",
					position: "sticky",
					bottom: 0,
					zIndex: 1,
				}}>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Balance: $ {balance}</Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Equity: ${equity}</Box>
				<Box sx={{ flex: 1 }}></Box>
				<Box sx={{ flex: 1 }}></Box>
				<Box sx={{ flex: 1 }}></Box>
				<Box sx={{ flex: 1, fontSize: 16, fontWeight: "bold" }}>Total Profit</Box>
				<Box sx={{ flex: 1, color: totalProfit >= 0 ? "#4599d9" : "#f74712", fontSize: 16, fontWeight: "bold" }}>{totalProfit}</Box>
			</Box>
		</Box>
	);
}
