import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";

export function Ticksy({ open, onClose }) {
	const [posts, setPosts] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const fetchPosts = async () => {
		setLoading(true);
		try {
			const response = await axios.post("https://mitserver.app:9005/getFeed", {
				page: page,
				limit: 10,
			});
			const newPosts = response.data.posts;

			setPosts((prevPosts) => {
				const existingPostIds = new Set(prevPosts.map((post) => post.id));
				const filteredNewPosts = newPosts.filter((post) => !existingPostIds.has(post.id));
				return [...prevPosts, ...filteredNewPosts].reverse();
			});
		} catch (error) {
			console.error("Error fetching posts:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (open) {
			fetchPosts(); // Fetch posts when the modal opens
		}
	}, [open, page]); // Depend on `open` and `page`

	const handleScroll = () => {
		if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
			return;
		}
		setPage((prevPage) => prevPage + 1);
	};

	useEffect(() => {
		if (!open) return; // Only add scroll listener if the modal is open

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [open, loading]);

	const formatDate = (dateString) => {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: false, // Use 24-hour format
		};
		return new Date(dateString).toLocaleString(undefined, options);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "flex-end",
				zIndex: 9999,
			}}>
			<Box
				sx={{
					width: "35%",
					height: "90vh",
					mt: 6,
					mr: 1,
					backgroundColor: "#282828",
					padding: "5px",
					borderRadius: "30px",
					boxShadow: "0 0 10px #f74712, 0 0 40px #f74712",
					"&::-webkit-scrollbar": {
						width: "8px",
					},
				}}>
				<Typography
					variant="h4"
					sx={{
						position: "sticky",
						color: "transparent",
						backgroundImage: "linear-gradient(45deg, #f74712, #f74712, #62fee1, #4599d9)",
						backgroundClip: "text",
						WebkitBackgroundClip: "text",
						fontFamily: "'Orbitron', sans-serif",
						textAlign: "center",
						fontWeight: "bold",
						mb: 4,
						mt: 2,
						zIndex: 1000,
						backgroundColor: "#282828",
					}}>
					TICKSY SOCIAL TRADING
				</Typography>

				<Box
					sx={{
						width: "100%",
						height: "77vh",
						mt: 6,
						mr: 1,
						backgroundColor: "transparent",
						overflowY: "auto",
						padding: "10px",
						"&::-webkit-scrollbar": {
							width: "8px",
						},
						"&::-webkit-scrollbar-track": {
							background: "#1a1f2d",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#282828",
							borderRadius: "4px",
						},
					}}>
					{posts.map((post) => (
						<Box
							key={post.id}
							sx={{
								mb: 4,
								pb: 4,
								borderBottom: "1px solid rgba(69, 153, 217, 0.2)",
							}}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									mb: 2,
								}}>
								<Box sx={{ alignItems: "center" }}>
									{/* <Avatar src={post.avatar} sx={{ mr: 2 }} /> */}
									<Typography
										variant="h6"
										sx={{
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
										}}>
										{post.author}
									</Typography>
									<Typography
										sx={{
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "0.7rem",
										}}>
										{post.symbol}
									</Typography>
								</Box>
								<Typography
									sx={{
										color: "#4599d9",
										fontFamily: "'Orbitron', sans-serif",
										fontSize: "0.7rem",
									}}>
									{formatDate(post.date)}
								</Typography>
							</Box>

							<Box sx={{ mb: 2, boxShadow: "0 0 20px #62fee1", borderRadius: "20px" }}>
								<img
									src={post.image}
									alt="Trading Analysis"
									style={{
										width: "100%",
										borderRadius: "20px",
									}}
								/>
							</Box>

							<Typography
								variant="h5"
								sx={{
									color: "#fff",
									fontFamily: "'Orbitron', sans-serif",
									marginBottom: "20px",
								}}>
								{post.title}
							</Typography>
							<Typography
								sx={{
									color: "#fff",
									mb: 2,
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{post.description}
							</Typography>

							<Box
								sx={{
									display: "flex",
									gap: 2,
									color: "#fff",
								}}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
										cursor: "pointer",
										"&:hover": { opacity: 0.8 },
									}}>
									<InstagramIcon sx={{ color: "#fcb045" }} /> {/* Set icon color */}
									<Typography>{post.instagram}</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
										cursor: "pointer",
										"&:hover": { opacity: 0.8 },
									}}>
									<TelegramIcon sx={{ color: "#fcb045" }} /> {/* Set icon color */}
									<Typography>{post.telegram}</Typography>
								</Box>
							</Box>
						</Box>
					))}
					{loading && <Typography sx={{ color: "#fff", textAlign: "center" }}>Loading...</Typography>}
				</Box>
			</Box>
		</Modal>
	);
}
