import { getLastCloses } from "../../streaming.js";

export const runVelandiaScalper = (tvWidget, candlesQty, slRetCandles = 6, tpRetCandles = 12, amount) => {
	console.log("RUN VELANDIA CAPITAL BOT");
	let cross1H = false;
	let emaData15M = [];
	let emaData5M = [];
	let ema50_5M = null;
	let ema200_5M = null;
	let ema50_15M = null;
	let ema200_15M = null;
	let totalCrosses = 0;
	let winningCrosses = 0;
	let totalProfit = 0;
	let totalLoss = 0;
	let maxDrawdown = 0;
	let currentDrawdown = 0;

	const init = async () => {
		const visibleRange = tvWidget.activeChart().getVisibleRange();
		const from = visibleRange.from * 1000;
		const to = visibleRange.to * 1000;
		const timeRange = to - from;
		const neededCandles15M = Math.ceil(timeRange / (900 * 1000));
		candlesQty = neededCandles15M;

		const ohlcData15M = await getLastCloses(900, neededCandles15M);
		console.log("15M Data received:", ohlcData15M.length);
		const needed5MCandles = Math.floor(ohlcData15M.length * 3 * 0.9);

		const ohlcData5M = await getLastCloses(300, needed5MCandles);
		console.log("5M Data received:", ohlcData5M.length);

		if (ohlcData5M.length > 0) {
			await processData15M(ohlcData15M);
			await processData5M(ohlcData5M);
		}

		// Subscribe to visible range changes
		// tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, () => {
		// 	init();
		// });
	};

	init();

	const calculateEMA = (period, data) => {
		let multiplier = 2 / (period + 1);
		return data.reduce((acc, val, index) => {
			if (index === 0) return [val.close];
			let ema = (val.close - acc[index - 1]) * multiplier + acc[index - 1];
			return [...acc, ema];
		}, []);
	};

	const drawEMA = (tvWidget, emaData, color50, color200) => {
		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema50 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema50 },
				],
				{
					shape: "trend_line",
					overrides: {
						linecolor: color50,
						linewidth: 4,
					},
				}
			);
		}

		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema200 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema200 },
				],
				{
					shape: "trend_line",
					overrides: {
						linecolor: color200,
						linewidth: 4,
					},
				}
			);
		}
	};

	const processData15M = (data) => {
		ema50_15M = calculateEMA(50, data);
		ema200_15M = calculateEMA(200, data);

		emaData15M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_15M[i],
			ema200: ema200_15M[i],
		}));
		console.log("15M Data processed:", emaData15M);
	};

	const processData5M = (data) => {
		ema50_5M = calculateEMA(50, data);
		ema200_5M = calculateEMA(200, data);

		emaData5M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_5M[i],
			ema200: ema200_5M[i],
		}));

		detectCross5M();
	};

	let condition5M = {
		active: false,
		time: null,
		direction: null,
	};

	let condition15M = {
		active: false,
		time: null,
	};

	const detectCross5M = () => {
		for (let i = 1; i < emaData5M.length; i++) {
			const previous = emaData5M[i - 1];
			const current = emaData5M[i];

			if (previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				condition5M = {
					active: true,
					time: current.time,
					direction: "up",
				};
				console.log("Primera condición activada: Cruce 5M alcista", new Date(previous.time).toString());
				markCrossM5(tvWidget, current.time, current.price, "up");
				monitorSecondCondition();
			} else if (previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				condition5M = {
					active: true,
					time: current.time,
					direction: "down",
				};
				console.log("Primera condición activada: Cruce 1H bajista", new Date(previous.time).toString());
				markCrossM5(tvWidget, current.time, current.price, "down");
				monitorSecondCondition();
			}
		}
	};

	const monitorSecondCondition = async () => {
		const endTime = condition5M.time + 4 * 60 * 60 * 1000;
		const check15M = emaData15M.filter((data) => data.time >= condition5M.time && data.time <= endTime);

		for (const data of check15M) {
			if (condition5M.direction === "up" && data.ema200 < data.ema50 && data.price > data.ema50) {
				condition15M = {
					active: true,
					time: data.time,
				};
				console.log("Segunda condición activada: Alineación 15M y precio arriba de EMA50");
				markCross15M(tvWidget, data.time, data.price, "up");
				monitorThirdCondition();
				await drawEMA(tvWidget, check15M, "orange", "green");

				break;
			} else if (condition5M.direction === "down" && data.ema200 > data.ema50 && data.price < data.ema50) {
				condition15M = {
					active: true,
					time: data.time,
				};
				console.log("Segunda condición activada: Alineación 15M y precio abajo de EMA50");
				markCross15M(tvWidget, data.time, data.price, "down");
				monitorThirdCondition();
				await drawEMA(tvWidget, check15M, "orange", "green");

				break;
			}
		}
	};

	const calculateCandleSizes = () => {
		const lastTwentyCandles = emaData5M.slice(-20);
		const priceMovements = lastTwentyCandles.map((candle) =>
			Math.abs(candle.price - (candle.open || lastTwentyCandles[lastTwentyCandles.indexOf(candle) - 1]?.price || candle.price))
		);

		const mean = priceMovements.reduce((a, b) => a + b, 0) / priceMovements.length;
		const variance = priceMovements.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / priceMovements.length;
		const stdDev = Math.sqrt(variance);
		const normalCandleSizes = priceMovements.filter((movement) => movement <= mean + 2 * stdDev);

		return normalCandleSizes.reduce((a, b) => a + b, 0) / normalCandleSizes.length;
	};

	const trackDrawdown = (profit) => {
		currentDrawdown = Math.min(currentDrawdown + profit, 0);
		maxDrawdown = Math.min(maxDrawdown, currentDrawdown);
	};

	const markProfit1M = (tvWidget, time, price, direction, profit) => {
		const color = direction === "win" ? "green" : "red";
		const prefix = direction === "win" ? "$" : "-$";

		tvWidget.activeChart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "balloon",
				text: `${prefix}${Math.abs(profit).toFixed(2)}`,
				overrides: {
					backgroundColor: color,
					color: "white",
					fontsize: 20,
					bold: true,
					zIndex: 100,
				},
				zOrder: "top",
			}
		);
	};

	const monitorThirdCondition = () => {
		const averageCandleSize = calculateCandleSizes();
		const endTime = condition15M.time + 12 * 60 * 60 * 1000;
		const check5M = emaData5M.filter((data) => data.time >= condition15M.time && data.time <= endTime);

		for (const data of check5M) {
			if (condition5M.direction === "up" && data.low < data.ema50) {
				totalCrosses++;
				const stopLoss = data.price - averageCandleSize * slRetCandles;
				const takeProfit = data.price + averageCandleSize * tpRetCandles;

				for (let j = check5M.indexOf(data) + 1; j < check5M.length; j++) {
					const closePrice = Number(check5M[j].price);

					if (closePrice <= stopLoss) {
						const candleMovements = Math.abs(closePrice - data.price) / averageCandleSize;
						const loss = candleMovements * amount;
						totalLoss += loss;
						trackDrawdown(-loss);
						markProfit1M(tvWidget, check5M[j].time, closePrice, "loss", loss);
						const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
						updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
						break;
					}

					if (closePrice >= takeProfit) {
						winningCrosses++;
						const candleMovements = Math.abs(data.price - closePrice) / averageCandleSize;
						const profit = candleMovements * amount;
						totalProfit += profit;
						trackDrawdown(profit);
						markProfit1M(tvWidget, check5M[j].time, closePrice, "win", profit);
						const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
						updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
						break;
					}
				}
				console.log("¡SEÑAL DE COMPRA! Tercera condición cumplida");
				markCrossM5(tvWidget, data.time, data.price, "trade");
				resetConditions();
				break;
			} else if (condition5M.direction === "down") {
				totalCrosses++;
				const stopLoss = data.price + averageCandleSize * slRetCandles;
				const takeProfit = data.price - averageCandleSize * tpRetCandles;

				for (let j = check5M.indexOf(data) + 1; j < check5M.length; j++) {
					const closePrice = Number(check5M[j].price);

					if (closePrice >= stopLoss) {
						const candleMovements = Math.abs(closePrice - data.price) / averageCandleSize;
						const loss = candleMovements * amount;
						totalLoss += loss;
						trackDrawdown(-loss);
						markProfit1M(tvWidget, check5M[j].time, closePrice, "loss", loss);
						const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
						updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
						break;
					}

					if (closePrice <= takeProfit) {
						winningCrosses++;
						const candleMovements = Math.abs(data.price - closePrice) / averageCandleSize;
						const profit = candleMovements * amount;
						totalProfit += profit;
						trackDrawdown(profit);
						markProfit1M(tvWidget, check5M[j].time, closePrice, "win", profit);
						const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
						updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
						break;
					}
				}
				console.log("¡SEÑAL DE VENTA! Tercera condición cumplida");
				markCrossM5(tvWidget, data.time, data.price, "trade");
				resetConditions();
				break;
			}
		}
	};

	const resetConditions = () => {
		condition5M = { active: false, time: null, direction: null };
		condition15M = { active: false, time: null };
	};

	const endTrade5M = (startTime) => {
		const filteredData = emaData5M.filter((data) => data.time >= startTime);

		for (let i = 1; i < filteredData.length; i++) {
			const previous = filteredData[i - 1];
			const current = filteredData[i];
			if (previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				const date = new Date(previous.time).toLocaleString();
				console.log(`Cruce al alza en 5M - Fecha: ${date}, Precio: ${previous.price}`);
				if (cross1H) {
					cross5M = true;
					markCrossM5(tvWidget, previous.time, previous.price, "up");
				}
				return;
			} else if (previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				const date = new Date(current.time).toLocaleString();
				console.log(`Cruce a la baja en 5M - Fecha: ${date}, Precio: ${current.price}`);
				if (cross1H) {
					cross5M = true;
					markCrossM5(tvWidget, previous.time, previous.price, "down");
				}
				return;
			}
		}
	};

	const markCross15M = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#90EE90" : "yellow";
		const cross = 0xf00d;
		const check = 0xf00c;
		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 20,
					zOrder: "top",
				},
				icon: check,
			}
		);
	};

	const markCrossM5 = (tvWidget, time, price, direction) => {
		const color = direction === "up" ? "#00E6F9" : direction === "trade" ? "#fff" : "#D631ED";

		const cross = 0xf00d;
		const check = 0xf00d;
		const flag = 0xf087;

		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 30,
					zOrder: "top",
				},
				icon: direction === "trade" ? flag : cross,
			}
		);
	};

	window.currentTvWidget = tvWidget;
	window.clearVelandiaCapital = (widget) => {
		console.log("Clearing spike shield");
		widget.activeChart().removeAllShapes();
		const statsBox = document.getElementById("statsBox");
		if (statsBox) {
			statsBox.remove();
		}
	};

	function updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss) {
		const statsBox = document.getElementById("statsBox");
		if (!statsBox) {
			const box = document.createElement("div");
			box.id = "statsBox";
			box.style.cssText = `
				position: absolute;
				bottom: 20px;
				left: 20px;
				width: 250px;
				height: 300px;
				background-color: rgba(23, 27, 38, 0.9);
				padding: 15px;
				border-radius: 8px;
				color: white;
				box-shadow: 0 0 5px #4599d9, 0 0 10px #4599d9;
				font-family: 'Orbitron', sans-serif;
				font-size: 12px;
				line-height: 1.5;
				cursor: move;
				user-select: none;
			`;
			document.body.appendChild(box);
			makeDraggable(box);
		}

		const netProfit = totalProfit - totalLoss;
		const profitColor = netProfit >= 0 ? "green" : "red";
		const days = Math.floor(candlesQty / (24 * 60));
		const remainingHours = Math.floor((candlesQty % (24 * 60)) / 60);

		document.getElementById("statsBox").innerHTML = `
			<div style="position: absolute; top: 5px; right: 10px; cursor: pointer; font-size: 16px; color: red;" 
			 onclick="window.clearVelandiaCapital(window.currentTvWidget)">✖</div>
			<p style="font-weight: bold; color: #4599d9;">Periodo: ${days}d ${remainingHours}h (${candlesQty} velas)</p>
			<p>Cruces Totales: <span style="float: right;">${totalCrosses}</span></p>
			<p>Cruces Ganadores: <span style="float: right;">${winningCrosses}</span></p>
			<p>Tasa de Éxito: <span style="float: right;">${winRate}%</span></p>
			<p>Drawdown Máximo: <span style="float: right; color: orange;">${maxDrawdown.toFixed(2)}</span></p>
			<hr style="border: 1px solid #4599d9; margin: 8px 0;">
			<p>Ganancia Total: <span style="float: right; color: ${profitColor};">${totalProfit.toFixed(2)}</span></p>
			<p>Pérdida Total: <span style="float: right; color: red;">${totalLoss.toFixed(2)}</span></p>
			<p>Resultado Neto: <span style="float: right; color: ${profitColor};">${netProfit.toFixed(2)}</span></p>
		`;
	}

	function makeDraggable(element) {
		let pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;
		element.onmousedown = dragMouseDown;

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			element.style.top = element.offsetTop - pos2 + "px";
			element.style.left = element.offsetLeft - pos1 + "px";
		}

		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}
};
