import { io } from "socket.io-client";

const serverPath = "https://mitserver.app:9005/";
const version = "V1.0.1";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
const query = { version: version };

if (user != null) {
	query.userId = user.id;
}
if (token != null) {
	query.token = token;
}

const socket = io(serverPath, {
	transports: ["websocket"],
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 2000,
	query: query,
});

const connectionStatus = document.getElementById("socketAPI");

let popup;

// function showReconnectPopup() {
// 	if (!popup) {
// 		popup = document.createElement("div");
// 		popup.style.position = "fixed";
// 		popup.style.top = "50%";
// 		popup.style.left = "50%";
// 		popup.style.transform = "translate(-50%, -50%)";
// 		popup.style.padding = "20px";
// 		popup.style.backgroundColor = "#333";
// 		popup.style.color = "white";
// 		popup.style.border = "1px solid #555";
// 		popup.style.borderRadius = "10px";
// 		popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
// 		popup.style.zIndex = "9999";
// 		popup.style.textAlign = "center";

// 		const message = document.createElement("p");
// 		message.textContent = "Tienes problemas de Conexion y la conexión se ha perdido. ¿Desea reconectar?";
// 		message.style.marginBottom = "20px";
// 		popup.appendChild(message);

// 		const reconnectButton = document.createElement("button");
// 		reconnectButton.textContent = "Reconectar";
// 		reconnectButton.style.backgroundColor = "#555";
// 		reconnectButton.style.color = "white";
// 		reconnectButton.style.border = "none";
// 		reconnectButton.style.padding = "10px 20px";
// 		reconnectButton.style.borderRadius = "5px";
// 		reconnectButton.style.cursor = "pointer";
// 		reconnectButton.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
// 		reconnectButton.style.display = "inline-block";
// 		reconnectButton.onclick = () => {
// 			window.location.reload();
// 		};
// 		popup.appendChild(reconnectButton);

// 		document.body.appendChild(popup);
// 	}
// }

// function hideReconnectPopup() {
// 	if (popup) {
// 		popup.remove();
// 		popup = null;
// 	}
// }

socket.on("connect", () => {
	connectionStatus.style.backgroundColor = "green";
	// hideReconnectPopup();
});

socket.on("disconnect", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

socket.on("close", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

socket.on("error", (error) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

document.addEventListener("visibilitychange", async () => {
	if (document.hidden) {
		// socket.disconnect();
	} else {
		const selectedAccount = await JSON.parse(localStorage.getItem("selectedAccount"));
		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		const token = await localStorage.getItem("token");

		if (symbolInfo) {
			let allPos = {
				symbol: symbolInfo.symbol,
				userId: selectedAccount.userId,
				account: selectedAccount.account,
			};
			socket.emit("allLines", allPos);
		}

		if (!socket.connected && selectedAccount && token) {
			socket.io.opts.query = {
				version: version,
				userId: selectedAccount.userId,
				token: token,
			};
			socket.connect();
		} else {
			// hideReconnectPopup();
		}
	}
});

export default socket;
